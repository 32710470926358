<div class="container-fluid">
    <div class="jumbotron jumbotron-fluid">
      <div class="row">
        <div class="col-sm col-md-9 col-lg-10 col-xl-10">
          <p class="heading">{{title}}</p>
        </div>
        <div class="col-sm col-md-3 col-lg-2 col-xl-2 mt-2">
          <button (click)="setStatus()" class="btn btn-outline-primary form-control" data-bs-toggle="modal" data-bs-target="#addUnitDetailsModal">
            <b>Add Unit</b>
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="row" [formGroup]="filterData">
      <div class="col">
        <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Unit Name" formControlName="ids"
          [settings]="UnitNamesDropDownSettings" [data]="UnitNamesDropDownList" (onDeSelect)="deSelectUnitNames()"
          (onDeSelectAll)="deSelectUnitNames()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col">
        <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Unit Code" formControlName="code"
          [settings]="codesDropDownSettings" [data]="codesDropDownList" (onDeSelect)="deSelectCodes()"
          (onDeSelectAll)="deSelectCodes()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col">
        <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Protocol Number" formControlName="protocolNumber"
          [settings]="protocolNumberDropDownSettings" [data]="protocolNumberDropDownList"
          (onDeSelect)="deSelectProtocolNumber()" (onDeSelectAll)="deSelectProtocolNumber()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col">
        <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Protocol Title" formControlName="protocolTitle"
          [settings]="protocolTitleDropDownSettings" [data]="protocolTitleDropDownList"
          (onDeSelect)="deSelectProtocolTitle()" (onDeSelectAll)="deSelectProtocolTitle()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col">
        <ng-multiselect-dropdown class="dropdown-list" placeholder="Is Locked" formControlName="status"
          [settings]="statusDropDownSettings" [data]="statusDropDownList"
          (onDeSelect)="deSelectStatus()" (onDeSelectAll)="deSelectStatus()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col-1">
        <button class="btn btn-outline-warning" (click)="getSearchData()"><b>Apply</b></button>
      </div>
      <div class="col-2">
        <button class="btn btn-outline-danger" (click)="clearFilters()"><b>Clear Filters</b></button>
      </div>
    </div> -->
    
    <div class="row mt-1" [formGroup]="filterFormGroup" >
      <div class="col-sm col-md-5 col-lg-5 col-xl-6 mb-1">
        <input type="text" class="form-control custom-pages-input" placeholder="Enter Unit Name" formControlName="unit">
      </div>
      <div class="col-sm col-md-1 col-lg-1 col-xl-2"></div>
      <div class="col-sm col-md-3 col-lg-3 col-xl-2 mb-1">
        <button type="button" class="btn btn-outline-warning form-control" (click)="searchUnitData()"><b>Apply</b></button>
      </div>
      <div class="col-sm col-md-3 col-lg-3 col-xl-2">
        <button type="button" class="btn btn-outline-danger form-control" (click)="clearUnitFilter()"><b>Clear Filters</b></button>
      </div>
    </div>

    <div class="col" *ngIf="unitListData.length; else noData">
      <div class="table-responsive">
        <table class="table table-light table-hover mt-3">
          <thead class="thead-light">
            <tr class="d-table-row">
              <th scope="col" class="col-1" >S. No.</th>
              <th scope="col" >Units</th>
              <th scope="col" >Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of unitListData ; let i = index;">
              <td>{{i+1}}</td>
              <td>{{row.unit}}</td>
              <td colspan="2">
                <button class="control-buttons" (click)="editUnit(row,i);" data-bs-toggle="modal" data-bs-target="#addUnitDetailsModal">
                  <span class="updateIcon"></span>
                </button> &nbsp;
                <button class="control-buttons" (click)="deleteUnit(row,i)">
                  <span class="deleteIcon"></span>
                </button> &nbsp;
            </td>
            </tr>
          </tbody>    
        </table>
        <div class="tableFooterHr"></div>
        <!-- <ngb-pagination [collectionSize]="totalPages" [(page)]="page" [boundaryLinks]="true"
          [rotate]="true" [ellipses]="false" (pageChange)="onChangePagination()">
          <ng-template ngbPaginationPages let-page let-pages="pages">
            <li class="ngb-custom-pages-item" *ngIf="pages.length > 0">
              <div class="mb-3 d-flex flex-nowrap px-2">
                <label id="paginationInputLabel" for="paginationInput" class="col-form-label me-2 ms-1">Page</label>
                <input
                  #i
                  type="text"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  class="form-control custom-pages-input"
                  id="paginationInput"
                  [value]="page"
                  (keyup.enter)="selectPage(i.value)"
                  (blur)="selectPage(i.value)"
                  (input)="formatInput($any($event).target)"
                  aria-labelledby="paginationInputLabel paginationDescription"
                  style="width: 2.5rem"
                />
                <span id="paginationDescription" class="col-form-label text-nowrap px-2"> of {{pages.length}}</span>
              </div>
            </li>
          </ng-template>
        </ngb-pagination> -->
      </div>
      </div>
    <ng-template #noData>
      <h5>No Data Available! &nbsp;
        <button (click)="addUnit()" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#addUnitDetailsModal">
          <b>Add Unit</b>
        </button>
      </h5>
    </ng-template>
  </div>

<!-- Modal -->
<div class="modal fade" id="addUnitDetailsModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5  class="modal-title" id="exampleModalLabel" *ngIf="!isEdit">Add Units Details</h5>
          <h5  class="modal-title" id="exampleModalLabel" *ngIf="isEdit">Update Unit Details</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
        </div>
        <form novalidate [formGroup]="unitForm">
          <!-- <input type="hidden" class="form-control" name="id" id="id"> -->
          <div class="modal-body">
            <div class="form-floating mb-3">
              <input type="text" formControlName="unit" class="form-control">
              <label for="therapeuticId">Enter Unit</label>
            </div>
          </div>
        </form>
        <div class="modal-footer">
          <button id="cancel" class="btn btn-outline-danger" data-bs-dismiss="modal" (click)="closeModal()"><b>Cancel</b></button>
          <button (click)="addUnit();" class="btn btn-outline-success" *ngIf="!isEdit" [disabled]="unitForm.invalid || loading">
            <b>
              Save <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
            </b>
          </button>
          <button *ngIf="isEdit" (click)="updateUnit()" class="btn btn-outline-primary" [disabled]="unitForm.invalid || loading">
            <b>
              Update <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
            </b>
          </button>
        </div>
      </div>
    </div>
</div>
  