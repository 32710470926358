import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CONSTANT_DATE, DEFAULT_UUID, OBJECT, VALIDATION_ACTION, VALIDATION_OPERATORS_DATE_ENUMS, VALIDATION_OPERATORS_ENUMS, componentTypeMap, ARROW, DATE_WINDOW_OPERATOR } from 'src/app/constant/constant';
import { cloneArray } from 'src/app/constant/globalFunction';
import { DEST_VALIDATION_COLUMN_REQUIRED_MSG, DEST_VALIDATION_FIELD_REQUIRED_MSG, DEST_VALIDATION_FORM_REQUIRED_MSG, DEST_VALIDATION_VISIT_REQUIRED_MSG, IMPACT_IS_NULL, LOGMAPPING_DELETE_CONFIRMATION, LOGMAPPING_STATUS_CONFIRMATION, VALIDATION_ACTION_REQUIRED_MSG, VALIDATION_COLUMN_REQUIRED_MSG, VALIDATION_DELETE_CONFIRMATION, VALIDATION_FIELD_REQUIRED_MSG, VALIDATION_FIELD_VALUE_REQUIRED_MSG, VALIDATION_FORM_REQUIRED_MSG, VALIDATION_JSON_FIELD_VALUE_REQUIRED_MSG, VALIDATION_MESSAGE_REQUIRED_MSG, VALIDATION_MULTIROW_COLUMN_REQUIRED_MSG, VALIDATION_OPERATOR_REQUIRED_MSG, VALIDATION_OPTION_REQUIRED_MSG, VALIDATION_STATUS_CONFIRMATION, VALIDATION_VISIT_DAY_MAX_LIMIT_MSG, VALIDATION_VISIT_DAY_REQUIRED_MSG, VALIDATION_VISIT_REQUIRED_MSG, VALIDATION_WINDOW_PERIOD_REQUIRED_MSG } from 'src/app/constant/responseMessage';
import { ValidationRuleService } from 'src/app/services/validation-rule.service';
import * as loadashJson from 'lodash';
import { FormService } from 'src/app/services/form.service';
import { forkJoin } from 'rxjs';
import { Role } from 'src/app/models/Role';

@Component({
  selector: 'app-validation-rule',
  templateUrl: './validation-rule.component.html',
  styleUrls: ['./validation-rule.component.css']
})
export class ValidationRuleComponent implements OnInit{
  
  @Input() fetchedFieldId:any
  @Input() fetchedDataTypeId:any
  @Input() formType:any
  ruleLength:any
  @Input() ruleName:any
  @Input() tabName:any = "validation"
  @Input() isTabValidation:any
  @Input() validationRuleId:any

  @Output() isMapping : EventEmitter<any> = new EventEmitter<any>();
  
  validationAndLogMapping:FormGroup

  isLocked:any
  roles:any

  fetchedVisitId:any
  fetchedFormId:any

  visitListMainData:any
  visitListData:any

  logVisitListMainData:any
  logVisitListData:any

  date:Date = new Date()

  fetchValidationData:any
  readOnlyRole:any;
  
  islogMappingtrue:any;
  timeJson?:any = {hour:"",minute:"",second:"",midDay:""};
  dateTimeJson?:any = {day:"",month:"",year:"",hour:"",minute:"",second:"",midDay:""};

  constructor(private validationAPI:ValidationRuleService, private toastr:ToastrService,private formBuilder:FormBuilder, private formAPI:FormService){
    this.validationAndLogMapping = new FormGroup({})
  }
  
  ngOnInit(): void {
    this.readOnlyRole = Role.ReadOnly;
    this.getStorageData()
    this.validationAndLogMapping = new FormGroup({
      id:new FormControl(),
      name:new FormControl(),
      formType:new FormControl(Number(this.formType)),
      rule:new FormArray([])
    })
    // this.getVisitFormData()
    this.getParallelApiCall()
  }

  getStorageData(){
    this.isLocked = sessionStorage.getItem("isLocked")
    this.roles = sessionStorage.getItem("role")
    if(this.validationRuleId == undefined || this.validationRuleId == null){
      const previewId:any = localStorage.getItem("previewId")
      if(previewId){
        this.fetchedFormId = previewId
      }else{
        this.fetchedFormId = localStorage.getItem("formId")
      }
      const visit:any = localStorage.getItem("visitIds")
      
      if(visit != 'null'){
        let visitIdParsed = JSON.parse(visit)
        if(typeof(visitIdParsed) == OBJECT && visitIdParsed.length == 0 && this.formType > 0){
          this.fetchedVisitId = [DEFAULT_UUID]
        }else{
          this.fetchedVisitId = visitIdParsed[0]
        }
      }
    }
  }

  // Add Rule Key Data
  addRule():FormGroup{
    return new FormGroup({
      id:new FormControl(),
      ruleId:new FormControl(),
      name:new FormControl(),
      equation:new FormControl(),
      displayEquation:new FormControl(),
      action:new FormControl(),
      message:new FormControl(),
      isNameEditable:new FormControl(false),
      editedName:new FormControl(),
      status:new FormControl(1),
      condition:new FormArray([]),
      impact:new FormArray([]),
      isLoading:new FormControl(false),
      tabName:new FormControl(this.tabName),
    })
  }

  // Add Condition FormGroup
  addCondition(i:any):FormGroup{
    return new FormGroup({
      id:new FormControl(),
      ruleId:new FormControl(),
      visitId:new FormControl(this.formType > 0 ? DEFAULT_UUID : null),
      formId:new FormControl(),
      fieldId:new FormControl(),
      multiRowId:new FormControl(),
      optionId:new FormControl(),
      operator:new FormControl(),
      isConstant:new FormControl(),
      dataTypeId:new FormControl(),
      visitOffset: new FormControl(),
      higherWindowPeriod: new FormControl(null),
      lowerWindowPeriod: new FormControl(null),
      isWindowPeriodChecked: new FormControl(),
      multiRowDataTypeId:new FormControl(),
      validationFormType:new FormControl(this.formType),
      fieldValue:new FormControl(),
      jsonFieldValue:new FormControl({}),
      tableValidationType:new FormControl(1),
      loperator:new FormControl(""),
      eqOrderId:new FormControl(i),
      orderId:new FormControl(i),
      uiFormSelect:new FormControl(),
      uiFieldSelect:new FormControl(),
      uiColumnSelect:new FormControl(),
      uiOperatorSelect:new FormControl(),
      uiOptionSelect:new FormControl(),
      uiFormattingKey:new FormControl(),
      uiHour:new FormControl(),
      uiMinute:new FormControl(),
      uiSecond:new FormControl(),
      uiMidDay:new FormControl(),
    })
  }

  // Add Impact FormGroup
  addImpact():FormGroup{
    return this.formBuilder.group({
      id:new FormControl(),
      ruleId:new FormControl(),
      visit:new FormControl(this.formType == 1 ? DEFAULT_UUID : null),
      form:new FormControl(),
      multiRowIds: new FormControl(),
      fields:[],
      uiFormSelect:new FormControl(),
      uiFieldSelect:new FormControl(),
      uiColumnSelect:new FormControl(),
      validationFormType:new FormControl(this.formType)
    })
  }
  
  //Add Impact For LogMapping FormGroup
  addLogMappingImpact():FormGroup{
    return new FormGroup({
      id:new FormControl(),
      ruleId:new FormControl(),
      visit:new FormControl(DEFAULT_UUID),
      form:new FormControl()
    })
  }

  //Getting FormArray Rule Key
  get getValidationOrLogMappingRuleKey(){
    return (this.validationAndLogMapping.get("rule") as FormArray)
  }

  //Getting Selected Rule Key Value
  getSelectedRuleKeyValue(keyName:any,i:any){
    return ((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(keyName)?.value
  }

  //Setting Selected Rule Key Value
  setSelectedRuleKeyValue(keyName:any,i:any,value:any){
    return((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(keyName)?.setValue(value)
  }

  getConditionOrImpactArrayRule(key:any,i:any){
    return (((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(key) as FormArray)
  }

  setSelectedKeyValueForConditionAndImpact(mainKey:any,keyName:any,i:any,j:any,value:any){    
    return ((((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(mainKey) as FormArray).get(j.toString()) as FormGroup).get(keyName)?.setValue(value)
  }

  getConditionOrImpactArrayKey(key:any,i:any,j:any){
    return ((((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(key) as FormArray).get(j.toString()) as FormGroup)
  }

  //for Formatting the jsonFieldData Key
  setSelectedKeyValueFormatting(jsonFormat:any,mainKey:any,keyName:any,i:any,value:any){
    jsonFormat[mainKey][i][keyName] = value
  }

  //For Getting Selected OptionId Name
  getOptionSelectedValue(data:any,conditionIndex:any,jsonFieldId:any){

    let value = data.condition[conditionIndex].uiOptionSelect
    if(value){
      for (let val = 0; val < value.length; val++) {
        if(jsonFieldId == value[val].id){
          
          return value[val].name
        }
      }
    }
  }

  getParallelApiCall(){
    forkJoin({
      visitData:this.formAPI.getVisitFormData(''),
      logData:this.formAPI.getLogFormAPIData()
    }).subscribe(res => {
      this.visitListMainData = res.visitData.responseObject
      const visits = res.visitData.responseObject['visit']
      this.visitListData = cloneArray(this.extractVisits(visits))
      this.logVisitListMainData = res.logData.responseObject
      const logData = res.logData.responseObject['visit']
      this.logVisitListData = cloneArray(this.extractVisits(logData))
      if(this.validationRuleId == undefined || this.validationRuleId == null){
        this.getValidationDataFromAPI()
      }else{
        this.getValidationDataFromRuleId()
      }
    })
  }

  setLogMappingAsActiveTab(){
    if(this.fetchValidationData != null && this.fetchValidationData != undefined){
      if(this.fetchValidationData.length != undefined ){
        for(let i = 0; i < this.fetchValidationData.length; i++){
          if( [1,2,3].includes(this.fetchValidationData[i].action) ){
            this.islogMappingtrue = false;
            break;
          }else{
            this.islogMappingtrue = true;
          }
        }
        if(this.islogMappingtrue){
          this.isMapping.emit({isOnlyLogMapping:true});
        }
      }else if( this.fetchValidationData[0].action == 4){
        this.isMapping.emit({isOnlyLogMapping:true});
      };
    }
  }
  //If parallel call is working fine then remove other api calls.
  //Getting Visit Data from API
  getVisitFormData(){
    this.formAPI.getVisitFormData('').subscribe(res => {
      this.visitListMainData = res.responseObject
      const visits = res.responseObject['visit']
      this.visitListData = cloneArray(this.extractVisits(visits))
      this.getLogFormData()
    })
  }

  // Extracting Visit Data for setting it to selectionBox
  extractVisits(visits: any): any[] {
    let visitData:any[] = []
    Object.keys(visits).map((key) => {
      visitData.push({ id: key, name: visits[key].name })
    })
    return visitData
  }

  //Getting Log Forms from API
  getLogFormData(){
    this.formAPI.getLogFormAPIData().subscribe(res => {
      this.logVisitListMainData = res.responseObject
      const visits = res.responseObject['visit']
      this.logVisitListData = cloneArray(this.extractVisits(visits))
    })
    // this.getLogFormFieldData()
  }

  getValidationDataFromAPI(){
    this.validationAPI.getValidation(this.formType > 0 ? DEFAULT_UUID : this.fetchedVisitId,this.fetchedFormId,this.fetchedFieldId).subscribe((res:any) => {
      if(typeof(res.responseObject) == OBJECT){
        this.fetchValidationData = cloneArray(res.responseObject)
      }else{
        this.fetchValidationData = null
      }
      if(this.fetchValidationData){
        this.addValidationOrLogMapping(true)
      }
      this.setLogMappingAsActiveTab();
    })
  }
  getValidationDataFromRuleId(){
    this.validationAPI.getValidationById(this.validationRuleId).subscribe((res:any) => {
      if(typeof(res.responseObject) == OBJECT){
        this.fetchValidationData = cloneArray(res.responseObject);
      }else{
        this.fetchValidationData = null
      }
      if(this.fetchValidationData){
        this.addValidationOrLogMapping(true)
      }
      this.setLogMappingAsActiveTab();
    })
  }
  //Adding Validation/Log Mapping Accordian
  addValidationOrLogMapping(isValidationDataFetched:any){
    if(isValidationDataFetched){
      this.validationAndLogMapping.reset()
      //TODO: remove if needed
      if(this.fetchValidationData){
        for (let validationDataIndex = 0; validationDataIndex < this.fetchValidationData.length; validationDataIndex++) {
          (this.validationAndLogMapping.get("rule") as FormArray).push(this.addRule());
          ((this.validationAndLogMapping.get("rule") as FormArray).get(validationDataIndex.toString()) as FormGroup).patchValue(this.fetchValidationData[validationDataIndex])
          this.setSelectedRuleKeyValue("id",validationDataIndex,this.fetchValidationData[validationDataIndex].ruleId)
          if([1,2,3].includes(this.fetchValidationData[validationDataIndex].action)){
            this.setSelectedRuleKeyValue("tabName",validationDataIndex,'validation')
            this.getConditionOrImpactArrayRule("impact",validationDataIndex).push(this.addImpact())
          }else{
            this.setSelectedRuleKeyValue("tabName",validationDataIndex,'logMapping')
            this.setSelectedRuleKeyValue("action",validationDataIndex,VALIDATION_ACTION[3].id)
            this.getConditionOrImpactArrayRule("impact",validationDataIndex).push(this.addLogMappingImpact())
          }
          this.getConditionOrImpactArrayRule("condition",validationDataIndex).push(this.addCondition(validationDataIndex))
          
        }
      }
    }else{
      (this.validationAndLogMapping.get("rule") as FormArray).push(this.addRule())
      let valdationLogMappingLength = (this.validationAndLogMapping.get("rule") as FormArray).length-1;
      this.setSelectedRuleKeyValue("name",valdationLogMappingLength,this.validationAndLogMapping.get("name")?.value)
      this.getConditionOrImpactArrayRule("condition",valdationLogMappingLength).push(this.addCondition(valdationLogMappingLength))
      let validationAndLogMappingTabName = this.getSelectedRuleKeyValue("tabName",valdationLogMappingLength)
      if(validationAndLogMappingTabName == "validation"){
        this.getConditionOrImpactArrayRule("impact",valdationLogMappingLength).push(this.addImpact())
      }else{
        this.setSelectedRuleKeyValue("action",valdationLogMappingLength,VALIDATION_ACTION[3].id)
        this.getConditionOrImpactArrayRule("impact",valdationLogMappingLength).push(this.addLogMappingImpact())
      }
      this.validationAndLogMapping.get("name")?.reset()
    }
  }
  
  //Change Validation/LogMapping Name
  onValidationOrLogMappingEditName(i:any,isEdited:boolean){
    let validationOrLogMappingName:any
    if(isEdited){
      validationOrLogMappingName = this.getSelectedRuleKeyValue("name",i)
      this.setSelectedRuleKeyValue("isNameEditable",i,true)
      this.setSelectedRuleKeyValue("editedName",i,validationOrLogMappingName)
    }else{
      validationOrLogMappingName = this.getSelectedRuleKeyValue("editedName",i)
      this.setSelectedRuleKeyValue("isNameEditable",i,false)
      this.setSelectedRuleKeyValue("name",i,validationOrLogMappingName)
      this.setSelectedRuleKeyValue("editedName",i,"")
    }
  }

  // Enable/Disable Validation/LogMapping
  enableDisableValidationOrLogMapping(event:any,status:any,ruleId:any,i:any,isValidation:boolean){    
    let statusData:any
    if(ruleId != undefined){
      let message:any
      if(isValidation){
        message = confirm(VALIDATION_STATUS_CONFIRMATION)
      }else{
        message = confirm(LOGMAPPING_STATUS_CONFIRMATION)
      }
      if(message){
        if(status == 1){
          event.source.checked = false
          event.checked = false
          statusData = 0
        }else{
          event.source.checked = true
          event.checked = true
          statusData = 1
        }
        this.setSelectedRuleKeyValue("status",i,statusData)
        let requestBody:any = {
          ruleIds:[ruleId],
          status: statusData
        }
        this.validationAPI.updateValidationStatus(requestBody).subscribe((res:any)=>{
          if(res.status == 0){
            this.toastr.success(res.message)
          }else{
            this.toastr.error(res.message)
          }
        })
      }else{
        if(status == 1){
          event.source.checked = true
          event.checked = true
          statusData = 1
        }else{
          event.source.checked = false
          event.checked = false
          statusData = 0
        }
        this.setSelectedRuleKeyValue("status",i,statusData)
      }
    }else{
      if(status == 1){
        event.source.checked = false
        event.checked = false
        statusData = 0
      }else{
        event.source.checked = true
        event.checked = true
        statusData = 1
      }
      this.setSelectedRuleKeyValue("status",i,statusData)
    }
  }

  // Removing Validation/LogMapping
  removeValidationOrLogMappingRule(i:any,isValidation:boolean){
    let ruleId = ((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get("ruleId")?.value
    let message:any
    if(isValidation){
      message = confirm(VALIDATION_DELETE_CONFIRMATION)
    }else{
      message = confirm(LOGMAPPING_DELETE_CONFIRMATION)
    }
    if(message){
      if(ruleId != null){
        this.validationAPI.deleteValidation(ruleId).subscribe(res=>{
          if(res.status == 0){
            this.toastr.success(res.message);
            this.getValidationOrLogMappingRuleKey.removeAt(i)
            // if(this.getValidationOrLogMappingRuleKey.length == 0){
            //   this.saveUpdateFlag = false
            // }else{
            //   this.saveUpdateFlag = true
            // }
          }
        })
      }else{
        this.getValidationOrLogMappingRuleKey.removeAt(i)
      }
    }
  }

  //Validate the Single Rule fields (all mandatory data exist or not)
  validateSingleValidationData(data:any){
    for (let conditionIndex = 0; conditionIndex < data.condition.length; conditionIndex++) {

      if( data.condition[conditionIndex].dataTypeId == 17 && data.action != VALIDATION_ACTION[3].id ){
        if( data.impact != null && data.impact != undefined ){
          if( data.impact[0].multiRowIds != null && data.impact[0].multiRowIds != undefined ){
            if( !( data.impact[0].multiRowIds.length > 0 && data.impact.length > 0 ) ){
              this.toastr.warning(VALIDATION_MULTIROW_COLUMN_REQUIRED_MSG);
              return false; // multirow column is not selected
            }
          }else {
            this.toastr.warning(VALIDATION_MULTIROW_COLUMN_REQUIRED_MSG);
            return false; // multirow column is not selected
          }
        }else {
          this.toastr.warning(IMPACT_IS_NULL);
          return false; // multirow column is not selected
        }
      }

      if(data.condition[conditionIndex].validationFormType == 0){
        if(data.condition[conditionIndex].visitId == '' || data.condition[conditionIndex].visitId == null){
          this.toastr.warning(VALIDATION_VISIT_REQUIRED_MSG)
          return false
        }

      }
      if(data.condition[conditionIndex].formId == '' || data.condition[conditionIndex].formId == null){
        this.toastr.warning(VALIDATION_FORM_REQUIRED_MSG)
        return false
      } else if(data.condition[conditionIndex].operator == DATE_WINDOW_OPERATOR && ((data.condition[conditionIndex].visitOffset == '' && data.condition[conditionIndex].visitOffset != 0)  || data.condition[conditionIndex].visitOffset == null)) {
        this.toastr.warning(VALIDATION_VISIT_DAY_REQUIRED_MSG)
        return false
      }else if(data.condition[conditionIndex].visitOffset >= 9999) {
        this.toastr.warning(VALIDATION_VISIT_DAY_MAX_LIMIT_MSG)
        return false
      }else if(data.condition[conditionIndex].fieldId == '' || data.condition[conditionIndex].fieldId == null){
        this.toastr.warning(VALIDATION_FIELD_REQUIRED_MSG)
        return false

      }else if(data.condition[conditionIndex].dataTypeId == 17 && 
        !data.condition[conditionIndex].multiRowId){
        this.toastr.warning(VALIDATION_COLUMN_REQUIRED_MSG)
        return false

      }else if(data.condition[conditionIndex].operator == '' || data.condition[conditionIndex].operator == null){
        this.toastr.warning(VALIDATION_OPERATOR_REQUIRED_MSG)
        return false

      }else if(data.condition[conditionIndex].isConstant == 1){
        
        if(data.condition[conditionIndex].dataTypeId == 1 || data.condition[conditionIndex].dataTypeId == 2 || data.condition[conditionIndex].dataTypeId == 3 || data.condition[conditionIndex].dataTypeId == 4 || data.condition[conditionIndex].dataTypeId == 5){
          
          if(data.condition[conditionIndex].fieldValue == '' || data.condition[conditionIndex].fieldValue == null){
            this.toastr.warning(VALIDATION_FIELD_VALUE_REQUIRED_MSG)
            return false
            
          }
        
        }else if(data.condition[conditionIndex].dataTypeId == 6 || data.condition[conditionIndex].dataTypeId == 7 || data.condition[conditionIndex].dataTypeId == 8 || data.condition[conditionIndex].dataTypeId == 12){
          
          if(data.condition[conditionIndex].jsonFieldValue == '' || data.condition[conditionIndex].jsonFieldValue == null || loadashJson.isEqual(data.condition[conditionIndex].jsonFieldValue,{})){
            this.toastr.warning(VALIDATION_JSON_FIELD_VALUE_REQUIRED_MSG)
            return false
          }
        }else if(data.condition[conditionIndex].dataTypeId == 17 && 
          (data.condition[conditionIndex].multiRowDataTypeId == 6 || 
          data.condition[conditionIndex].multiRowDataTypeId == 7 ||
          data.condition[conditionIndex].multiRowDataTypeId == 8) && 
          !data.condition[conditionIndex].jsonFieldValue){
          this.toastr.warning(VALIDATION_OPTION_REQUIRED_MSG)
          return false

        }

      }else if(data.condition[conditionIndex].isConstant == 2){
        
        if(data.condition[conditionIndex].dest != undefined){
          
          if(data.condition[conditionIndex].validationFormType == 0){
            if(data.condition[conditionIndex].dest['visitId'] == '' || data.condition[conditionIndex].dest['visitId'] == null){
              this.toastr.warning(DEST_VALIDATION_VISIT_REQUIRED_MSG)
              return false

            }
          }
          if(data.condition[conditionIndex].dest['formId'] == '' || data.condition[conditionIndex].dest['formId'] == null){
            this.toastr.warning(DEST_VALIDATION_FORM_REQUIRED_MSG)
            return false

          }else if(data.condition[conditionIndex].dest['fieldId'] == '' || data.condition[conditionIndex].dest['fieldId'] == null){
            this.toastr.warning(DEST_VALIDATION_FIELD_REQUIRED_MSG)
            return false

          }else if(data.condition[conditionIndex].dest['dataTypeId'] == 17 && !data.condition[conditionIndex].dest['multiRowId']){
            this.toastr.warning(DEST_VALIDATION_COLUMN_REQUIRED_MSG)
            return false

          }
        }
      }
    }

    if(data.action == '' || data.action == null){
      this.toastr.warning(VALIDATION_ACTION_REQUIRED_MSG)
      return false

    }

    if(data.action != VALIDATION_ACTION[3].id){
      if(data.message == '' || data.message == null){
        this.toastr.warning(VALIDATION_MESSAGE_REQUIRED_MSG)
        return false
      }
    }

    for (let impactIndex = 0; impactIndex < data.impact.length; impactIndex++) {
      if(data.tabName == 'validation' && data.uniqueId == data.impact[impactIndex].uniqueId){
        if(data.impact[impactIndex].validationFormType == 0){
          if(data.impact[impactIndex].visit == '' || data.impact[impactIndex].visit == null){
            this.toastr.warning(VALIDATION_VISIT_REQUIRED_MSG)
            return false
  
          }
        }
        
        if(data.impact[impactIndex].form == '' || data.impact[impactIndex].form == null){
          this.toastr.warning(VALIDATION_FORM_REQUIRED_MSG)
          return false
  
        }else if(data.impact[impactIndex].fields == '' || data.impact[impactIndex].fields == null || data.impact[impactIndex].fields.length == 0){
          this.toastr.warning(VALIDATION_FIELD_REQUIRED_MSG)
          return false
        }
      }else{
        if(data.impact[impactIndex].form == '' || data.impact[impactIndex].form == null){
          this.toastr.warning(VALIDATION_FORM_REQUIRED_MSG)
          return false
  
        }
      }

    }
    
    return true
  }

  //Set Data into JsonFieldData according to the Backend Requirment
  setConditionFieldIds(data:any){
    let emptyJson:any = {}
    if(data.condition){
      for (let conditionIndex = 0; conditionIndex < data.condition.length; conditionIndex++) {
        let prepareJson:any = {};
        let dataTypeId = data.condition[conditionIndex].dataTypeId == 17 ? data.condition[conditionIndex].multiRowDataTypeId : data.condition[conditionIndex].dataTypeId;
        let jsonFieldData = data.condition[conditionIndex].jsonFieldValue
        if(typeof(jsonFieldData) == OBJECT){
          this.setSelectedKeyValueFormatting(data,"condition","optionId",conditionIndex,null)
        }else{
          if(dataTypeId == componentTypeMap[17].id){
            this.setSelectedKeyValueFormatting(data,"condition","optionId",conditionIndex,null)
          }else{
            this.setSelectedKeyValueFormatting(data,"condition","optionId",conditionIndex,jsonFieldData)

          }
        }
        
        let jsonFieldValueData = this.getOptionSelectedValue(data,conditionIndex,jsonFieldData)
        if([componentTypeMap[5].id,componentTypeMap[6].id].includes(dataTypeId)){
          prepareJson[jsonFieldData] = true
          this.setSelectedKeyValueFormatting(data,"condition","jsonFieldValue",conditionIndex,prepareJson)
        }
        else if(dataTypeId == componentTypeMap[7].id){
          prepareJson[jsonFieldData] = jsonFieldValueData
          this.setSelectedKeyValueFormatting(data,"condition","jsonFieldValue",conditionIndex,prepareJson)
        }
        else if(dataTypeId == componentTypeMap[11].id){
          this.setSelectedKeyValueFormatting(data,"condition","jsonFieldValue",conditionIndex,data.condition[conditionIndex].jsonFieldValue)
        }
        else if([componentTypeMap[12].id,componentTypeMap[18].id].includes(dataTypeId)){
          let hour:any = data.condition[conditionIndex].uiHour
          let minute:any = data.condition[conditionIndex].uiMinute
          let second:any = data.condition[conditionIndex].uiSecond
          let midDay:any = data.condition[conditionIndex].uiMidDay
          let dateData:any = data.condition[conditionIndex].jsonFieldValue
          let time:any = dataTypeId == componentTypeMap[12].id ? cloneArray(this.timeJson) : cloneArray(this.dateTimeJson)
          if(dateData.day){
            time['day'] = dateData.day
          }else{
            delete time['day']
          }
          if(dateData.month){
            time['month'] = dateData.month
          }else{
            delete time['month']
          }
          if(dateData.year){
            time['year'] = dateData.year
          }else{
            delete time['year']
          }
          if(hour){
            time['hour'] = hour
          }else{
            delete time['hour']
          }
          if(minute){
            time['minute'] = minute
          }else{
            delete time['minute']
          }
          if(second){
            time['second'] = second
          }else{
            delete time['second']
          }
          if(midDay){
            time['midDay'] = midDay
          }else{
            delete time['midDay']
          }
          this.setSelectedKeyValueFormatting(data,"condition","jsonFieldValue",conditionIndex,time)
        }
        else if(dataTypeId == componentTypeMap[16].id){
          this.setSelectedKeyValueFormatting(data,"condition","jsonFieldValue",conditionIndex,data.condition[conditionIndex].jsonFieldValue)
          
        }
        else if(dataTypeId == componentTypeMap[17].id){
          prepareJson[jsonFieldData] = true
          this.setSelectedKeyValueFormatting(data,"condition","jsonFieldValue",conditionIndex,prepareJson)
          this.setSelectedKeyValueFormatting(data,"condition","fieldValue",conditionIndex,jsonFieldValueData)
          
        }else{
          this.setSelectedKeyValueFormatting(data,"condition","jsonFieldValue",conditionIndex,emptyJson)

        }
        
      }
    }
    if(data.impact){
      for (let impactIndex = 0; impactIndex < data.impact.length; impactIndex++) {

        let id = data.impact[impactIndex].id;
        data.impact[impactIndex].form = Number(data.impact[impactIndex].form)
        data.impact[impactIndex].multiRowIds = data.impact[impactIndex].multiRowIds == '' ? [] : data.impact[impactIndex].multiRowIds;
        let fields = data.impact[impactIndex].fields;
        let multiRowIds:any

        if(fields){
          let fieldData = fields.map((item:any) => { if(item != undefined){ return Number(item.id)}else{return}})
          if(fieldData != undefined){
            this.setSelectedKeyValueFormatting(data,"impact","fields",impactIndex,fieldData)
          }
        }
        if(this.fetchedDataTypeId == componentTypeMap[16].id){
          multiRowIds = data.impact[impactIndex].multiRowIds;
          if(multiRowIds){
            let columnData = multiRowIds.map((item:any) => { if(item != undefined){ return Number(item.id)}else{return}})
            if(columnData != undefined){
              this.setSelectedKeyValueFormatting(data,"impact","multiRowIds",impactIndex,columnData)
            }
          }
        }else if(this.fetchedDataTypeId == componentTypeMap[17].id){
          multiRowIds = data.impact[impactIndex].multiRowIds;
          if(multiRowIds){
            let columnData = multiRowIds.map((item:any) => { if(item != undefined){ return Number(item.tableColumnIndex)}else{return}})
            if(columnData != undefined){
              this.setSelectedKeyValueFormatting(data,"impact","multiRowIds",impactIndex,columnData)
            }
          }
        }
      }
    }
    
  }

  getFormula(datatype:string ,value1:any,value2:any, operator:any,loperator?:any, isText:boolean = false){
    let lOperation = loperator == null ? "" : loperator;
    if(isText)
      return datatype + "('" + value1 + "','" + value2 + "') " +lOperation
    else
      return datatype + "(" + value1 + (operator ? " " + operator + " " : ", ") + value2 + ") " + lOperation
  }

  createQFormula(data:any,j:any, isDestination: boolean){
    let eq = "Q{";

    let visitId = isDestination ? data.condition[j].dest['visitId'] : data.condition[j].visitId
    let formId = isDestination ? data.condition[j].dest['formId'] : data.condition[j].formId
    let fieldId = isDestination ? data.condition[j].dest['fieldId'] : data.condition[j].fieldId
    
    let multiRowId:any
    if(this.fetchedDataTypeId == componentTypeMap[16].id){
      multiRowId = isDestination ? data.condition[j].dest['multiRowId'] : data.condition[j].multiRowId
    }else if (this.fetchedDataTypeId == componentTypeMap[17].id){
      multiRowId = isDestination ? "{"+data.condition[j].dest['multiRowId']+"}" : "{"+data.condition[j].multiRowId+"}"
    }
    
    let isConstant = data.condition[j].isConstant;
    let dataType = data.condition[j].dataTypeId;
    
    let columnDataType:any 
    if(this.fetchedDataTypeId == componentTypeMap[16].id){
      columnDataType = isDestination ? data.condition[j].dest['multiRowDataTypeId'] : data.condition[j].multiRowDataTypeId;
    }else if(this.fetchedDataTypeId == componentTypeMap[17].id){
      columnDataType = isDestination ? data.condition[j].dest['multiRowDataTypeId'] : data.condition[j].multiRowDataTypeId;
    }
    
    let option = data.condition[j].jsonFieldValue != null ? Object.keys(data.condition[j].jsonFieldValue)[0] : null
    
    eq += (visitId && visitId != DEFAULT_UUID) ? visitId + "." : DEFAULT_UUID + ".";
    
    eq += formId+"."+fieldId;

    if(multiRowId){
      eq += "."+multiRowId;
    }
    
    if(isConstant == 1){
      if([17].includes(dataType) && [6, 7, 8].includes(columnDataType)){
        eq += "." + data.condition[j].optionId;
      }
      // else if([18].includes(dataType) && [6, 7, 8].includes(columnDataType)){
        // eq += "." + "{" + data.condition[j].optionId + "}";
        // eq += "." + "{op}";

      // }
      else if([6, 7, 8].includes(dataType)){
        eq += "." + option;
      }
    }
    return eq + "}";
  }

  getDisplayEquationFormula(value1:any, value2:any, operator:any, loperator?:any){
    let lOperation = loperator == null ? "" : loperator;
    return value1 + " " + operator + " " + value2 + " " +lOperation
  }

  createDisplayEquationFormula(data:any,j:any, isDestination: boolean){
    let eq = "( ";
    let visitId = isDestination ? data.condition[j].dest ? data.condition[j].dest['visitId'] : null : data.condition[j].visitId
    let visitName:any
    if(visitId && visitId != DEFAULT_UUID){
      let validationFormType:any
      if(isDestination){
        validationFormType = data.condition[j].dest['validationFormType']
        visitName = validationFormType == 0 ? this.visitListData.find((key:any) => key.id == data.condition[j].dest['visitId']).name : "Logs Visit"
      }else{
        validationFormType = data.condition[j].validationFormType
        visitName = validationFormType == 0 ? this.visitListData.find((key:any) => key.id == data.condition[j].visitId).name : "Logs Visit"
        
      }
      // visitName = isDestination ? this.visitListData.find((key:any) => key.id == data.condition[j].dest['visitId']).name : this.visitListData.find((key:any) => key.id == data.condition[j].visitId).name
    }
  //  else  visitName = 'Log Visit'; If logVisit is to be shown in logforms display name then  uncomment this line
    let formName = isDestination ? data.condition[j].dest ? data.condition[j].dest['uiFormSelect'].find((key:any) => key.formId == data.condition[j].dest['formId']).formName : null : data.condition[j].uiFormSelect.find((key:any) => key.formId == data.condition[j].formId).formName
    let fieldName = isDestination ? data.condition[j].dest ? data.condition[j].dest['uiFieldSelect'].find((key:any) => key.id == data.condition[j].dest['fieldId']).label : null : data.condition[j].uiFieldSelect.find((key:any) => key.id == data.condition[j].fieldId).label
    
    let multiRowName:any
    console.log("DATA :: ",data);
    
    if(this.fetchedDataTypeId == componentTypeMap[16].id){
      if(isDestination && data.condition[j].dest != undefined && data.condition[j].dest['multiRowDataTypeId'] != null){
        multiRowName = data.condition[j].dest['uiColumnSelect'].find((key:any) => key.id == data.condition[j].dest['multiRowId']).colHeading
      }else{
        multiRowName = data.condition[j].uiColumnSelect.find((key:any) => key.id == data.condition[j].multiRowId).colHeading
      }
    }else if (this.fetchedDataTypeId == componentTypeMap[17].id){
      multiRowName = isDestination ? data.condition[j].dest ? data.condition[j].dest['uiColumnSelect'].find((key:any) => key.tableColumnIndex == data.condition[j].dest['multiRowId']).colHeading : null : data.condition[j].uiColumnSelect.find((key:any) => key.tableColumnIndex == data.condition[j].multiRowId).colHeading
    }
    // eq += (visitId && visitId != DEFAULT_UUID) ? visitName + ARROW : ""; // remove Defualt_UUID Constraint if log visit is to be shown in display Name
    eq += visitName ? visitName + ARROW + formName + ARROW + fieldName : formName + ARROW + fieldName;

    if(multiRowName){
      eq += ARROW + multiRowName;
    }

    return eq + " )";
  }

  dataAndOperatorsConbinations(data:any,j:any){
    let dataTypeId:any;
    let loperator:any;
    let value:any;
    let operator:any;
    let formula:any;
    let visitOffset:any;
    let lowerWindowPeriod:any;
    let higherWindowPeriod:any;


    let destination:any = data.condition[j].dest
    
    // let operator = this.getSelectedKeyValue(data,"condition","operator",i,j)?.value
    operator = data.condition[j].operator
    
    // let value = this.getSelectedKeyValue(data,"condition","fieldValue",i,j)?.value
    value = data.condition[j].fieldValue
    
    // let loperator = this.getSelectedKeyValue(data,"condition","loperator",i,j)?.value
    loperator = data.condition[j].loperator
    
    // let formula = this.createQFormula(data,i,j)
    formula = this.createQFormula(data, j, false);

    visitOffset = data.condition[j].visitOffset;

    lowerWindowPeriod = data.condition[j].lowerWindowPeriod;

    higherWindowPeriod = data.condition[j].higherWindowPeriod;

    let displayEquationFormula = this.createDisplayEquationFormula(data,j, false)
    
    let jsonFieldValue = data.condition[j].jsonFieldValue
    let selectedIdValue:any;
    let jsonData:any
    if(data.condition[j].uiFieldSelect && data.condition[j].uiFieldSelect.length > 0){
      for (let index = 0; index < data.condition[j].uiFieldSelect.length; index++) {
        if(data.condition[j].fieldId == data.condition[j].uiFieldSelect[index].id){
          
          let dataTypeid = data.condition[j].dataTypeId;
          let fieldData = data.condition[j].uiFieldSelect;
          
          if(![17,18].includes(dataTypeid)){
            if(fieldData[index].options){
              for (let opt = 0; opt < fieldData[index].options.length; opt++){
                selectedIdValue = jsonFieldValue[data.condition[j].uiFieldSelect[index].options[opt].id];
                if(selectedIdValue)
                  break;
              }
            }
          }else if (dataTypeid == componentTypeMap[16].id){
            if(fieldData.columnData){
              for (let colData = 0; colData < fieldData.columnData.length; colData++){
                for(let options = 0; options < fieldData.columnData[colData].options.length; options++){
                  selectedIdValue = jsonFieldValue[fieldData.columnData[colData].options[options].id]
      
                  if(selectedIdValue)
                    break;
                }
              }
            }
          }
        }
      }
    }

    if(destination != undefined){
      // subCondition = this.createSubQFormula(data,i,j)
      if(this.fetchedDataTypeId == componentTypeMap[16].id){
        dataTypeId = data.condition[j].dest['dataTypeId'] == 17 ? data.condition[j].dest['multiRowDataTypeId'] : data.condition[j].dest['dataTypeId']
      }else if(this.fetchedDataTypeId == componentTypeMap[17].id){
        dataTypeId = data.condition[j].dest['dataTypeId'] == 18 ? data.condition[j].dest['multiRowDataTypeId'] : data.condition[j].dest['dataTypeId']
      }else{
        dataTypeId = data.condition[j].dest['dataTypeId']
      }
      loperator = data.condition[j].dest["loperator"]

    }else{
      if(data.condition[j].dataTypeId == componentTypeMap[16].id){
        dataTypeId = data.condition[j].multiRowDataTypeId
      }else if(data.condition[j].dataTypeId == componentTypeMap[17].id){
        dataTypeId = data.condition[j].multiRowDataTypeId
      }else{
        dataTypeId = data.condition[j].dataTypeId
      }
    }
    if(dataTypeId == 12 && jsonFieldValue){
      jsonData = jsonFieldValue['year'] + "-" + jsonFieldValue['month'] + "-" + jsonFieldValue['day']
    }
    if([componentTypeMap[12].id,componentTypeMap[18].id].includes(dataTypeId)){
      jsonData = this.createTimeComponentString(jsonFieldValue,jsonData,dataTypeId)
    }
    if(dataTypeId == 8){
      jsonData = selectedIdValue
    }
    if(this.fetchedDataTypeId == componentTypeMap[17].id){
      if(dataTypeId == 6 || dataTypeId == 7){
        jsonData = selectedIdValue
      }
    }
    let equation:string = ""
    let displayEquation:string = ""
    
    let equationString = VALIDATION_OPERATORS_ENUMS[operator];
    let destFormula = this.createQFormula(data, j, destination ? true : false);
    let destDisplayEquationFormula = this.createDisplayEquationFormula(data, j, true);
    if ([componentTypeMap[0].id,componentTypeMap[1].id,componentTypeMap[3].id,componentTypeMap[4].id].includes(dataTypeId)){
      equation = this.getFormula(equationString + "Text", formula, destination ? destFormula : value, undefined, loperator, true)
      displayEquation = this.getDisplayEquationFormula(displayEquationFormula,destination ? destDisplayEquationFormula : value, operator, loperator)
    }
    else if (dataTypeId == componentTypeMap[2].id){
      if ([">", ">=", "<", "<="].includes(operator)){
        equation = this.getFormula("", formula, destination ? destFormula : Number(value), operator, loperator, false);
      }else{
        equation = this.getFormula(equationString, formula, destination ? destFormula : value , undefined, loperator, false)
      }
      displayEquation = this.getDisplayEquationFormula(displayEquationFormula,destination ? destDisplayEquationFormula : value, operator, loperator)
    }
    else if ([componentTypeMap[5].id,componentTypeMap[6].id].includes(dataTypeId) ){
      if(this.fetchedDataTypeId == componentTypeMap[17].id){
        equation = this.getFormula(equationString + "Text", formula, destination ? destFormula : data.condition[j].fieldValue, undefined, loperator, true)
        displayEquation = this.getDisplayEquationFormula(displayEquationFormula, data.condition[j].fieldValue, operator, loperator)
      }else{
        equation = this.getFormula(equationString, formula, destination ? destFormula : "true", undefined, loperator, false)
        let optionName = data?.condition[j]?.jsonFieldValue != null ? data?.condition[j]?.uiOptionSelect?.find((key:any) => key.id == Object.keys(data.condition[j].jsonFieldValue)[0]).name : null
        displayEquation = this.getDisplayEquationFormula(displayEquationFormula, optionName, operator, loperator)
      }
    }
    else if (dataTypeId == componentTypeMap[7].id){
      if(this.fetchedDataTypeId == componentTypeMap[17].id){
        equation = this.getFormula(equationString + "Text", formula, destination ? destFormula : data.condition[j].fieldValue, undefined, loperator, true)
      }else{
        equation = this.getFormula(equationString + "Text", formula, destination ? destFormula :  jsonData, undefined, loperator, true)
      }
      displayEquation = this.getDisplayEquationFormula(displayEquationFormula,destination ? destDisplayEquationFormula : value, operator, loperator)
    }
    else if ([componentTypeMap[11].id,componentTypeMap[18].id].includes(dataTypeId)){
      equationString = VALIDATION_OPERATORS_DATE_ENUMS[operator]
      equation = this.getFormula(equationString, formula, destination ? destFormula : jsonData, undefined, loperator, true);
      if( operator == DATE_WINDOW_OPERATOR){
        equation = `${equation.slice(0, -2)},${visitOffset},${lowerWindowPeriod},${higherWindowPeriod}) `;
        displayEquation = this.getDisplayEquationForDate(data,j,destDisplayEquationFormula,equationString,visitOffset,lowerWindowPeriod,higherWindowPeriod);
      }else {
        displayEquation = this.getDisplayEquationFormula(displayEquationFormula,destination ? destDisplayEquationFormula : jsonData , operator, loperator)
      }
      console.log(displayEquation);
    }
    else if ([componentTypeMap[12].id].includes(dataTypeId)){
      equationString = VALIDATION_OPERATORS_DATE_ENUMS[operator]
      equation = this.getFormula(equationString , formula, destination ? CONSTANT_DATE + destFormula : CONSTANT_DATE + jsonData, undefined, loperator, true)
      displayEquation = this.getDisplayEquationFormula(displayEquationFormula,destination ? CONSTANT_DATE + destDisplayEquationFormula : CONSTANT_DATE + jsonData , operator, loperator)
    }
    
    return {equation:equation,displayEquation:displayEquation}
  }
  getDisplayEquationForDate(data: any, j: any, destDisplayEquationFormula: string, equationString: string, visitOffset?: any, lowerWindowPeriod?: any, higherWindowPeriod?: any): string {
    let validationFiledForDisplayEquation = this.createDisplayEquationFormula(data,j,false);
    let dispeqn = equationString.concat("( ", validationFiledForDisplayEquation," , ",destDisplayEquationFormula," , ","visitOffset: ",visitOffset.toString()," , lowerWindowPeriod: ",lowerWindowPeriod != null ? lowerWindowPeriod.toString():"0"," , higherWindowPeriod: ",higherWindowPeriod != null ? higherWindowPeriod.toString():"0"," )"); 
    return dispeqn;
  }

  //Setting Equation into the Request Body
  makeEquation(data:any){
    if(data){
      let equation:string = ""
      let displayEquation:string = ""
      for (let i = 0; i < data.condition.length; i++) {
        equation += " " + this.dataAndOperatorsConbinations(data,i).equation
        displayEquation += " " + this.dataAndOperatorsConbinations(data,i).displayEquation
      }
      data.equation = equation
      data.displayEquation = displayEquation
    }
  }
  
  //Adding Validation into DB
  addSingleValidation(item:any,ruleIndex:any){
    let response:any = {
      id:(this.getValidationOrLogMappingRuleKey.get(ruleIndex.toString())as FormArray).get("id")?.value,
      rule:[(this.getValidationOrLogMappingRuleKey.get(ruleIndex.toString())as FormArray).value]
    }
    let responseBody = cloneArray(response);
    if( !this.validateSingleValidationData(responseBody.rule[0])){
      return
    }
    this.setConditionFieldIds(responseBody.rule[0])
    this.makeEquation(responseBody.rule[0])
    
    delete responseBody.rule[0].editedName
    delete responseBody.rule[0].isLoading
    delete responseBody.rule[0].isNameEditable
    delete responseBody.rule[0].tabName
    delete responseBody.rule[0].uniqueId

    for(let j = 0; j < responseBody.rule[0].condition.length; j++){

      let item1 = responseBody.rule[0].condition[j];

      if(typeof(item1.visitId) == 'object'){
        item1.visitId = item1.visitId[0]
      }

      delete item1.uiColumnSelect;
      delete item1.uiFieldSelect;
      delete item1.uiFormSelect;
      delete item1.uiOperatorSelect;
      delete item1.uiOptionSelect;
      delete item1.uniqueId
      delete item1.uiHour
      delete item1.uiMinute
      delete item1.uiSecond
      delete item1.uiMidDay

      if(item1.dest){
        if(typeof(item1.dest.visitId) == 'object'){
          item1.dest.visitId = item1.dest.visitId[0]
        }
        delete item1.dest.uiVisitSelect;
        delete item1.dest.uiFormSelect;
        delete item1.dest.uiFieldSelect;
        delete item1.dest.uiColumnSelect;
        delete item1.dest.uniqueId;
        delete item1.dest.tabName;
      }
    }

    for(let j = 0; j < responseBody.rule[0].impact.length; j++){

      let item1 = responseBody.rule[0].impact[j];
      console.log("IMPACT :: ",responseBody.rule[0].impact);
      
      if(typeof(item1.visit) == 'object'){
        item1.visit = item1.visit[0]
      }

      delete item1.uiFormSelect;
      delete item1.uiFieldSelect;
      delete item1.uiColumnSelect;
      delete item1.uniqueId;
      delete item1.tabName;

    }

    console.log("RESPONSE BODY :: ",responseBody);
    if(responseBody.id == null){
      this.setSelectedRuleKeyValue("isLoading",ruleIndex,true)
      this.validationAPI.addValidation(responseBody).subscribe(res=>{
        if(res.status != 0 ){
          this.toastr.error(res.message)
        }else{
          this.toastr.success(res.message)
          this.setSelectedRuleKeyValue("id",ruleIndex,res.responseObject.ruleId)
          this.setSelectedRuleKeyValue("ruleId",ruleIndex,res.responseObject.ruleId)
          let ruleLength = (this.validationAndLogMapping.get("rule") as FormArray)
          if(ruleLength.length > 0){
            for (let i = 0; i < ruleLength.length; i++) {
              let conditionLength = this.getConditionOrImpactArrayRule("condition",i)
              let impactLength = this.getConditionOrImpactArrayRule("impact",i)
              if(conditionLength.length > 0){
                for (let j = 0; j < conditionLength.length; j++) {
                  if(res.responseObject.conditionIds){
                    for (let k = 0; k < res.responseObject.conditionIds.length; k++) {
                      this.setSelectedKeyValueForConditionAndImpact("condition","id",i,j,res.responseObject.conditionIds[k])
                      this.setSelectedKeyValueForConditionAndImpact("condition","ruleId",i,j,res.responseObject.ruleId)
                    }
                  }
                }
              }
              if(impactLength.length > 0){
                for (let k = 0; k < impactLength.length; k++) {
                  if(res.responseObject.impactIds){
                    for (let l = 0; l < res.responseObject.impactIds.length; l++) {
                      this.setSelectedKeyValueForConditionAndImpact("impact","id",i,k,res.responseObject.impactIds[l])
                      this.setSelectedKeyValueForConditionAndImpact("impact","ruleId",i,k,res.responseObject.ruleId)
                    }
                  }
                }
              }
            }
          }
          this.setSelectedRuleKeyValue("isLoading",ruleIndex,false)
        }
      },error => {
        this.setSelectedRuleKeyValue("isLoading",ruleIndex,false)
      })
    }else{
      this.setSelectedRuleKeyValue("isLoading",ruleIndex,true)
      this.validationAPI.updateValidation(responseBody).subscribe(res=>{
        if(res.status != 0){
          this.toastr.error(res.message)
        }else{
          this.toastr.success(res.message)
        }
        this.setSelectedRuleKeyValue("isLoading",ruleIndex,false)
      },error => {
        this.setSelectedRuleKeyValue("isLoading",ruleIndex,false)
      })
    }
  }

  //Set Time Component Data into String
  createTimeComponentString(jsonFieldValue:any,jsonData:any,dataTypeId:any){
    jsonData = ""
    if(jsonFieldValue){
      if(dataTypeId == componentTypeMap[18].id){
        if(jsonFieldValue['year']){
          jsonData = jsonData + jsonFieldValue['year']
        }
        if(jsonFieldValue['year'] && jsonFieldValue['month']){
          jsonData = jsonData + "-"
        }
        if(jsonFieldValue['month']){
          jsonData = jsonData + jsonFieldValue['month']
        }
        if(jsonFieldValue['month'] && jsonFieldValue['day']){
          jsonData = jsonData + "-"
        }
        if(jsonFieldValue['day']){
          jsonData = jsonData + jsonFieldValue['day']
        }
        if(jsonFieldValue['day'] && jsonFieldValue['hour']){
          jsonData = jsonData + " "
        }
      }
      if(jsonFieldValue['hour']){
        jsonData = jsonData + jsonFieldValue['hour']
      }
      if(jsonFieldValue['hour'] && jsonFieldValue['minute']){
        jsonData = jsonData + ":"
      }
      if(jsonFieldValue['minute']){
        jsonData = jsonData + jsonFieldValue['minute']
      }
      if(jsonFieldValue['minute'] && jsonFieldValue['second']){
        jsonData = jsonData + ":"
      }
      if(jsonFieldValue['second']){
        jsonData = jsonData + jsonFieldValue['second']
      }
      if(jsonFieldValue['second'] && jsonFieldValue['midDay']){
        jsonData = jsonData + " "
      }
      if(jsonFieldValue['midDay']){
        jsonData = jsonData + jsonFieldValue['midDay']
      }
    }
    return jsonData
  }
}
