import { NgModule } from "@angular/core";
import { RandomizationFieldContainerComponent } from "./randomization-field-container/randomization-field-container.component";
import { RandomizationFieldContainerDialogComponent } from "./randomization-field-container-dialog/randomization-field-container-dialog.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ToastrModule } from "ngx-toastr";

@NgModule({
    declarations: [
        RandomizationFieldContainerComponent,
        RandomizationFieldContainerDialogComponent
    ],
    exports: [
        RandomizationFieldContainerComponent,
        RandomizationFieldContainerDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule, 
        ReactiveFormsModule,
        ToastrModule   
    ]
})

export class RandomizationModule {

}