<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Signature</h5>
        <button class="btn-close" data-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body" *ngIf="!isSignatureExist && isUserReset">
        <div class="row">
            <div class="col-2">
                <label for="radioSelect" class="form-label">Sign As: </label>
            </div>
            <div class="col-10">
                <div class="col-5 form-check" *ngFor="let item of signSelection;let i = index">
                    <input class="form-check-input" type="radio" name="signAs" id="signAs" [value]="item.id" (change)="onSignSelected($event,i)">
                    <label class="form-check-label" for="signAs">
                      {{item.name}}
                    </label>
                </div>
            </div>
        </div>
        <br>
        <div *ngIf="signAs == 1">
            <div class="row">
                <div class="col-2">
                    <label for="forText">Font: </label>
                </div>
                <div class="col-10">
                    <select class="form-select" [(ngModel)]="font" (change)="onFontChange($event.target)">
                        <option *ngFor="let item of fontSelection" [value]="item.name" 
                        [style.font-family]="item.name">
                            {{item.name}}
                        </option>
                    </select>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-2">
                    <label for="forText">Text: </label>
                </div>
                <div class="col-10">
                    <input type="text" class="form-control capture" name="forText" autocomplete="off"
                    id="textSign" [style.font-family]="font" style="text-align: center;font-weight: bold;">
                </div>
            </div>
            <br>
        </div>
        <div class="row" *ngIf="signAs == 2">
            <div class="col-2">
                <label for="forSign">Sign: </label>
            </div>
            <div class="col-10">
                <canvas class="canvas" id="signCanvas" width="380" height="100" 
                style="border:1px solid black;transform-origin: left top;"></canvas>
            </div>
        </div>
        <div class="row" *ngIf="signAs == 2">
            <div class="col-2">
            </div>
            <div class="col-10" style="margin-top: 10px;">
                <button title="Add Signature" id="addSign" class="btn btn-outline-dark addSign" (click)="startBrush()">
                    <i class="fa fa-paint-brush"></i>
                </button>
                &nbsp;&nbsp;
                <button title="Clear All" id="eraseSign" class="btn btn-outline-dark eraseSign" (click)="clearBrush()">
                    <i class="fa fa-cut"></i>
                </button>
            </div>
        </div>
        <div *ngIf="signAs == 3">
            <div class="row">
                <div class="col">
                    <label for="formFile" class="form-label"><b>File:</b></label>
                    <input class="form-control" type="file" id="formFile" accept="image/png, image/jpeg, image/jpg" (change)="onFileSelect($event.target)">
                    <span id="fileSizeError" style="color: red;"></span>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-body text-center" *ngIf="isSignatureExist">
        <img class="img-fluid" src="{{'data:image/png;base64,'+getSign}}" alt="">
    </div>
    <div class="modal-footer">
        <button type="button" id="cancel" class="btn btn-outline-danger" 
        data-bs-dismiss="modal" (click)="closeModal()"><b>Close</b></button> &nbsp;&nbsp;
        <button class="btn btn-outline-primary" (click)="addSignature()" *ngIf="isVisible" [hidden]="isLocked == 1" [disabled]="loading">
            <b>
                Save <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
            </b>
        </button>
        <button class="btn btn-outline-primary" (click)="reviewForms()" *ngIf="isReviewDataExist" [hidden]="isLocked == 1" [disabled]="reviewLoading">
            <b>
                Sign <span class="spinner-border spinner-border-sm" *ngIf="reviewLoading" role="status" aria-hidden="true"></span>
            </b>
        </button>
        <button class="btn btn-outline-secondary" (click)="unReviewForms()" *ngIf="isUnReviewDataExist" [hidden]="isLocked == 1" [disabled]="unReviewLoading">
            <b>
                Unsign <span class="spinner-border spinner-border-sm" *ngIf="unReviewLoading" role="status" aria-hidden="true"></span>
            </b>
        </button>
        <button class="btn btn-outline-primary" (click)="resetSignature()" *ngIf="!isUserReset">
            <b>Reset Signature</b>
        </button>
    </div>
</div>