<form [formGroup]="randomizationForm">
  <div *ngFor="let field of randomizationFieldsDetails" 
      class="form-group row mb-3">
  <label class="col-sm-3 col-form-label" 
        [for]="field?.label" >
    <b>{{field?.label}}</b>
  </label>
  <div [ngClass]="roleId == 1 ? 'col' : 'col-4'">
      <input *ngIf="field?.orderId != 3; else dateTemplate;" 
          type="text" 
          class="form-control" 
          [formControlName]="field?.formControlName || 'number'"
          [id]="field?.label" 
          [name]="field?.label"
          [placeholder]="field?.label" 
          autocomplete="off">
      <ng-template #dateTemplate>
        <div class="input-group">
          <input class="form-control"
                [id]="field?.label" 
                [placeholder]="field?.label"
                [formControlName]="field?.formControlName || 'date'" 
                [name]="field?.label"
                ngbDatepicker
                [readOnly]="true">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary disabled" type="button">
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</form>