import { ToastrService } from 'ngx-toastr';
import { QUERY_PRIORITY, QUERY_STATUS, QUERY_TYPE } from './../../../constant/constant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FieldQueryService } from '../../../services/field-query.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { ASSIGNED_TO_REQUIRED_MSG, QUERY_CLOSED_MSG, QUERY_REPLY_SUCCESS_MSG, QUERY_SELECT_ASSIGNED_TO_SUCCESS_MSG, QUERY_SELECT_STATUS_SUCCESS_MSG, REASON_REQUIRED_MSG } from 'src/app/constant/responseMessage';
import { Role } from 'src/app/models/Role';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { hideDataWithTypeAndStatusAndRole } from 'src/app/constant/globalFunction';
import { QUERY_HIGH_PRIORITY_ICON_COLOR, QUERY_LOW_PRIORITY_ICON_COLOR, QUERY_MEDIUM_PRIORITY_ICON_COLOR } from 'src/app/constant/color-code';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-query-raised',
  templateUrl: './query-raised.component.html',
  styleUrls: ['./query-raised.component.css']
})
export class QueryRaisedComponent implements OnInit {

  @Input() form:any;@Input() formName:any;
  @Input() field:any;@Input() fieldName:any;
  @Input() visit:any;@Input() visitName:any;
  @Input() subjectId:any;@Input() subjectAutoId:any;
  @Input() site:any;@Input() siteName:any;
  @Input() queryId:any;@Input() query:any;
  @Input() assignedToName:any;@Input() assignedByName:any;
  @Input() aroleId:any;@Input() assignedTo:any;
  @Input() queryPriority:any;
  @Input() status:any;
  @Input() oroleId:any;
  @Input() type:any;
  @Input() ownerId:any;
  @Input() rowId:any;
  @Input() multiRowId:any;@Input() multiRowName:any;

  fieldQueryDetail:FormGroup
  closeQueries:FormGroup

  roles:any
  userId:any
  userName:any
  showReplyComponent:any
  queryStatus:any = QUERY_STATUS
  queryPriorityData:any = QUERY_PRIORITY

  fieldId:any
  statusData:any

  closeQueryArr:any[] = []
  tableData:any[] = []

  assignedToData:any
  roleId:any
  roleData:any

  LOCKED:any = sessionStorage.getItem("isLocked")
  isLocked:number = parseInt(this.LOCKED)

  loading:boolean = false
  replyLoading:boolean = false

  selectedAssignedRoleId:any;
  selectedAssignedId:any;
  assignedToQuery:any

  queryLowPriorityIconColor:any = QUERY_LOW_PRIORITY_ICON_COLOR
  queryMediumPriorityIconColor:any = QUERY_MEDIUM_PRIORITY_ICON_COLOR
  queryHighPriorityIconColor:any = QUERY_HIGH_PRIORITY_ICON_COLOR

  studyDateFormat:any
  constructor(private fieldQueryAPI:FieldQueryService,private userAPI:UserService,
    private router:Router,private modelService:NgbModal,private toastr:ToastrService,private roleAPI:RoleService) {

    this.fieldQueryDetail = new FormGroup({
      queryId:new FormControl(),
      status:new FormControl(1,[Validators.required]),
      message:new FormControl(null,[Validators.required]),
      assignedTo:new FormControl(),
      aroleId:new FormControl(this.roleId),
      assignedByRoleId:new FormControl(),
      createdBy:new FormControl()
    })
    this.closeQueries = new FormGroup({
      id:new FormControl(''),
      formId:new FormControl(''),
      fieldId:new FormControl(''),
      subjectId:new FormControl(''),
      visitId:new FormControl(''),
      status:new FormControl(''),
      siteId:new FormControl(''),
      roleId:new FormControl(),
      reason:new FormControl(),
      assignedTo:new FormControl(),
      aroleId:new FormControl()
    })
  }

  ngOnInit(): void {
    this.statusData = this.status
    this.getStorageData()
    this.getRoleList()
    this.getQueryData()
    this.getUserIdAndUserNameByData()
  }

  getStorageData(){
    this.roles = sessionStorage.getItem("role")
    this.userId = sessionStorage.getItem("userId")
    this.userName = sessionStorage.getItem("userName")
    this.studyDateFormat = sessionStorage.getItem("studyDateFormat")
  }

  getRoleList(){
    this.roleAPI.getRoleList().subscribe(res =>{
      this.roleData = res.responseObject
    })
  }

  getQueryData(){
    this.fieldQueryAPI.findByQueryId(this.query).subscribe(res =>{
      this.tableData = res
    })
  }

  closeModal(){
    this.modelService.dismissAll()
    //location.reload()
  }

  gotoQuestion(){
    
    let params:any = {
      siteId:this.site,
      subjectId:this.subjectId,
      visitId:this.visit,
      formId:this.form,
      fieldId:this.field,
    }
    //Added this condition because for simple Fields rowId is going -1, 
    //because of this getting error in multi-row component
    if(this.rowId != null && this.rowId != -1){
      params['rowId'] = this.rowId
    }
    if(this.roles == Role.CRC){
      this.router.navigate(['/layout/data-entry'],{queryParams:params})
    }else{
      this.router.navigate(['/layout/data-entry-review'],{queryParams:params})

    }
    this.modelService.dismissAll()
  }

  hiddenByStatus(){
    return hideDataWithTypeAndStatusAndRole(this.type,this.status,this.roles)
  }

  validateData(){
    let fieldValue = this.fieldQueryDetail.getRawValue()
    if(fieldValue.message == '' || fieldValue.message == null){
      this.toastr.warning(QUERY_REPLY_SUCCESS_MSG)
      return false
    }
    if(fieldValue.status == '' || fieldValue.status == null){
      this.toastr.warning(QUERY_SELECT_STATUS_SUCCESS_MSG)
      return false
    }
    if(this.fieldQueryDetail.get("assignedTo")?.value == '' || this.fieldQueryDetail.get("assignedTo")?.value == null){
      this.toastr.warning(QUERY_SELECT_ASSIGNED_TO_SUCCESS_MSG)
      return false
    }
    return true
  }

  replayDisable:boolean = false
  raisedFieldQueryReply(){
    if(this.validateData()){
      this.fieldQueryDetail.controls['queryId'].setValue(this.query)
      this.fieldQueryDetail.controls['createdBy'].setValue(this.userId)
      this.fieldQueryDetail.controls['assignedByRoleId'].setValue(this.roles)
      this.fieldQueryDetail.controls['assignedByRoleId'].setValue(this.roles)
      let assigned:string = this.fieldQueryDetail.get("assignedTo")?.value
      let assignedData = assigned.split(",")
      let requestBody = {
        queryId:this.query,
        message:this.fieldQueryDetail.get("message")?.value,
        status:this.fieldQueryDetail.get("status")?.getRawValue(),
        assignedTo:assignedData[0],
        aroleId:this.aRoleId,
        assignedByRoleId:this.roles,
      }
      
      this.replyLoading = true
      this.replayDisable = true
      this.fieldQueryAPI.postFieldQueryDetail(requestBody).subscribe(res => {
        if(res.status != 0){
          this.toastr.error(res.message)
        }else{
          this.toastr.success(res.message)
          this.getQueryData()
          this.fieldQueryDetail.reset()
          if(this.roles == Role.CRC || this.roles == Role.Investigator){
            this.fieldQueryDetail.get("status")?.setValue(requestBody.status)
          }
          this.replayDisable = false
        }
        this.replyLoading = false
      },err=>{
        this.toastr.error(err.message)
        this.replyLoading = false
      })
    }
  }
  disableCloseQuery(){
    let returnData:any
    if(this.status == QUERY_STATUS[2].id){
      returnData = true
      return returnData
    
    }else{
      
      if(this.type == QUERY_TYPE[0].id){
        
        if(this.roles == Role.CRA || 
          // this.roles == Role.Monitor || 
          this.roles == Role.DM || this.roles == Role.Investigator){
          returnData = false
          return returnData
        
        }else{
          returnData = true
          return returnData
        
        }
      
      }else if(this.oroleId == this.roles){
        returnData = false
        return returnData
      
      }else{
        returnData = true
        return returnData
      }
    }
  }

  disableReOpenQuery(){
    let returnData:any
    if(this.status == QUERY_STATUS[2].id){
      returnData = false
      return returnData
    
    }else{
      
      if(this.type == QUERY_TYPE[0].id){
        
        if(this.roles == Role.CRA || 
          // this.roles == Role.Monitor || 
          this.roles == Role.DM || this.roles == Role.Investigator){
          returnData = false
          return returnData
        
        }else{
          returnData = true
          return returnData
        
        }
      
      }else if(this.oroleId == this.roles){
        returnData = false
        return returnData
      
      }else{
        returnData = true
        return returnData
      }
    }
  }

  reOpenQuery(status:any){
    this.closeQueries.controls['id'].setValue(this.query)
    this.closeQueries.controls['formId'].setValue(this.form)
    this.closeQueries.controls['fieldId'].setValue(this.field)
    this.closeQueries.controls['subjectId'].setValue(this.subjectId)
    this.closeQueries.controls['visitId'].setValue(this.visit)
    this.closeQueries.controls['status'].setValue(status)
    this.closeQueries.controls['siteId'].setValue(Number(this.site))
    this.closeQueries.controls['roleId'].setValue(Number(this.roles))
    this.closeQueries.controls['assignedTo'].setValue(this.withdrawAssignedTo)
    this.closeQueries.controls['aroleId'].setValue(Number(this.withdrawARoleId))
    this.closeQueryArr.push(this.closeQueries.value)
    this.loading = true
    if(this.closeQueries.controls['assignedTo'].value == undefined){
      this.toastr.warning(ASSIGNED_TO_REQUIRED_MSG);
      return;
    }if(this.closeQueries.controls['reason'].value == null || this.closeQueries.controls['reason'].value == undefined){
      this.toastr.warning(REASON_REQUIRED_MSG);
      return;

    }
    if(this.closeQueryArr.length != 0){
      this.fieldQueryAPI.queryStatus(this.closeQueryArr).subscribe(res=>{
        if(res.status != 0){
          this.toastr.error(res.message)
        }else{
          this.toastr.success(res.message)
          let ref = document.getElementById('cancel');
          ref?.click();
        }
        this.loading = false
      },err=>{
        this.loading = false
        this.toastr.error(err.message)
      })
    }else{
      this.toastr.warning(QUERY_CLOSED_MSG)
    }
    this.closeQueryArr = []
  }
  
  resetClose(){
    this.closeQueries.reset()

  }

  getUserIdAndUserNameByData(){
    this.userAPI.getUserIdAndUserNameByRoleId(this.site).subscribe(res=>{
      this.assignedToData = res.responseObject
      this.assignedToQuery = res.responseObject
      
    })
  }

  onAssignedSelected(event:any){
    let assignedId = event.value
    
    let strArr = assignedId.split(",")
    this.assignedToSelected = strArr[0]
    this.aRoleId = strArr[1]
  }

  withdrawAssignedTo:any
  withdrawARoleId:any
  onAssignedToStatusChange(event:any){
    let assignedId = event.value
    let strArr = assignedId.split(",")
    this.withdrawARoleId = strArr[1]
    this.withdrawAssignedTo = strArr[0]
  }

  aRoleId:any
  assignedToSelected:any
  replyComponent(){
    this.showReplyComponent = true
    if(this.roles == Role.CRC){
      this.fieldQueryDetail.get("status")?.setValue(this.queryStatus[1].id)
      this.fieldQueryDetail.get("status")?.disable()
      // this.fieldQueryDetail.get("assignedTo")?.disable()
      // this.fieldQueryDetail.get("assignedTo")?.setValue(this.ownerId + "," + this.oroleId )
      // this.aRoleId = this.oroleId
      // this.assignedToSelected = this.ownerId
    }else{
      // this.fieldQueryDetail.get("assignedTo")?.setValue(this.tableData[0].assignedById + "," + this.tableData[0].assignedByRoleId )
      this.aRoleId = this.tableData[0].assignedByRoleId
      this.assignedToSelected = this.tableData[0].assignedById
      
    }
  }
  raisedFieldQueryCancel(){
    this.showReplyComponent = false
    this.fieldQueryDetail.get("status")?.enable()
  }
}

