import { ToastrService } from 'ngx-toastr'
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, DEFAULT_UUID, DEFAULT_VISIT_NAME, STATUS_DATA } from './../../constant/constant'
import { ReviewService } from '../../services/review.service'
import { Role } from 'src/app/models/Role'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { distinctUntilChanged } from 'rxjs/operators'
import { CRA_REVIEW_STATUS, INVESTIGATOR_REVIEW_STATUS, MONITOR_DM_REVIEW_STATUS } from '../../models/data-entry.const'
import { CRA_REVIEW_SUCCESS_MSG, CRA_UNREVIEW_SUCCESS_MSG, DATA_MANAGER, DATA_NOT_AVAILABLE_MSG, DATA_NOT_SELECT_MSG, DATA_REVIEW, DM_REVIEW_SUCCESS_MSG, DM_UNREVIEW_SUCCESS_MSG, FORM_ANY_ONE_SELECT_MSG, FORM_REVIEW_NOT_REVIEWED_MSG, NON_SDV_DATAPOINT, NON_SDV_FORM_LEVEL, READ_ONLY, REVIEW_DM_DATAPOINT, REVIEW_DM_FORM_LEVEL, SDV_DATAPOINT, SDV_FORM_LEVEL, SIGN_FORM, SITE_ANY_ONE_SELECT_MSG, SITE_SELECTION_ERROR_MSG, SITE_SELECT_MSG, SUBJECT_ANY_ONE_SELECT_MSG, SUBJECT_SELECTION_MSG, UNREVIEW_DM_DATAPOINT, UNREVIEW_DM_FORM_LEVEL, VISIT_ANY_ONE_SELECT_MSG } from 'src/app/constant/responseMessage'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AddSignatureComponent } from '../popup/add-signature/add-signature.component'
import { IDropdownSettings } from 'ng-multiselect-dropdown'
import { FormService } from 'src/app/services/form.service'
import { SubjectService } from 'src/app/services/subject.service'
import { SiteService } from 'src/app/services/site.service'
import { ERROR_ICON_COLOR, PARTIAL_SUCCESS_ICON_COLOR, SUCCESS_ICON_COLOR } from 'src/app/constant/color-code'

@Component({
  selector: 'app-data-entry-review',
  templateUrl: './data-entry-review.component.html',
  styleUrls: ['./data-entry-review.component.css']
})
export class DataEntryReviewComponent implements OnInit {
  searchLoading:boolean = false;

  //For title of the component
  title:any

  testVar:any

  //FormGroup
  dataReviewForm: FormGroup
  updateFormStatus:FormGroup
  
  //API Data Store In
  visitFormData:any = [];
  siteSubjectData:any = [];
  tableData: any[] = []

  //Site Data
  sitesList: any = []
  sitesArray: any[] = []
  selectedSiteIds: any[] = []
  siteDropDownSettings: IDropdownSettings = {}
  
  //Subject Data
  subjectsList: any = []
  subjectsArray: any[] = []
  selectedSubjectIds: any[] = []
  subjectDropDownSettings: IDropdownSettings = {}
  
  //visit Data
  visitsList: any = []
  visitsArray: any[] = []
  selectedVisitIds: any[] = []
  visitDropdownSettings: IDropdownSettings = {}
  
  //form Data
  formsList: any = []
  formsArray: any[] = []
  formReviewData:any[] = []
  selectedFormIds: any[] = []
  formDropdownSettings: IDropdownSettings = {}

  //logForm Data
  logFormList: any = []
  logFormArray: any[] = []
  logFormReviewData:any[] = []
  selectedLogFormIds: any[] = []
  logFormDropdownSetting: IDropdownSettings = {}
  
  //status Data
  statusList: any
  selectedStatusId:any = null
  statusDropdownSettings: IDropdownSettings = {}

  //Local Storage Data var
  roles:any
  isLocked:any

  //Select All CheckBox
  isChecked:boolean = true
  checkedList:any = {}
  masterSelected: boolean = false

  //Authentication
  Authorities =
  {
    DataReview: [Role.CRA, Role.admin],
    DataManager: [Role.DM, Role.admin]
  }

  //For Routers
  routers:any

  //Loader
  reviewLoading:boolean = false
  unReviewLoading:boolean = false
  monitorLockLoading:boolean = false
  monitorUnLockLoading:boolean = false
  piSignLoading:boolean = false
  piUnsignLoading:boolean = false

  //Monitor Lock
  forms:any[] = []
  subjects:any[] = []
  visits:any[] = []
  status:any[] = []
  monitorLockData:any

  //Pagination
  pageNo:number = DEFAULT_PAGE_NO
  pageSize:number = DEFAULT_PAGE_SIZE
  page:number = 1;
  totalPages:any

  //Query params data
  queryStringData: any = {};
  isApplyClicked: any

  defaultUuid:any = DEFAULT_UUID
  successIconColor:any = SUCCESS_ICON_COLOR
  errorIconColor:any = ERROR_ICON_COLOR
  readOnlyRole:any;
  constructor(private formBuilder: FormBuilder, private reviewAPI: ReviewService, private subjectApiSvc: SubjectService, 
    private router: Router,private review:ReviewService,private toastr:ToastrService,
    private modalService:NgbModal,private formAPI:FormService,private route:ActivatedRoute, 
    private siteAPI:SiteService) {
    this.dataReviewForm = this.formBuilder.group({
      site: [null],
      subject: [null],
      visit: [null],
      form: [null],
      logForm: [null],
      status: [null]
    })
    this.updateFormStatus = this.formBuilder.group({
      forms: [],
      status: null,
      // roleId:null
    })
    this.routers = router.url
  }

  ngOnInit(): void {
    this.queryStringData = this.route.snapshot.queryParamMap;
    this.getStorageData()
    this.getStatusListForRole()
    this.setDropdownSettings()
    // this.getAllDropdownData()
    this.getVisitFormData('')
    this.getSiteSubjectData()
    this.populateSubjectDropdown()
    this.populateFormDropdown()
    this.getSelectedSubject()
    this.getSelectedForms()
    this.getSelectedStatus()
  }

  getStatusListForRole(){    
    if(this.roles == Role.CRA){
      this.statusList = CRA_REVIEW_STATUS
      this.title = DATA_REVIEW
    }
    else if(this.roles == Role.ReadOnly){
      this.statusList = MONITOR_DM_REVIEW_STATUS
      this.title = READ_ONLY
    }
    else if(this.roles == Role.DM){
      this.statusList = MONITOR_DM_REVIEW_STATUS
      this.title = DATA_MANAGER
    }
    else if(this.roles == Role.Investigator){
      this.statusList = INVESTIGATOR_REVIEW_STATUS
      this.title = SIGN_FORM
    }
    else if(this.roles == Role.Biostatistician){
      this.title = READ_ONLY
    }
  }

  getStatusDesign(crc:any,cra:any,dm:any,inv:any,softLock:any){
    let data:any = {}
    if(crc == 0){
      data['title'] = "Not submitted"
      data['color'] = ERROR_ICON_COLOR
    }
    if(crc == 1){
      data['title'] = "Partial submit for review"
      data['color'] = PARTIAL_SUCCESS_ICON_COLOR
    }
    if(crc == 2){
      data['title'] = "Submit for review"
      data['color'] = SUCCESS_ICON_COLOR
    }
    if(cra == 0){
      data['title'] = "Non SDV"
      data['color'] = ERROR_ICON_COLOR
    }
    if(cra == 1){
      data['title'] = "Partial SDV"
      data['color'] = PARTIAL_SUCCESS_ICON_COLOR
    }
    if(cra == 2){
      data['title'] = "SDV"
      data['color'] = SUCCESS_ICON_COLOR
    }
    if(dm == 0){
      data['title'] = "DM Unreview"
      data['color'] = ERROR_ICON_COLOR
    }
    if(dm == 1){
      data['title'] = "Partial DM Review"
      data['color'] = PARTIAL_SUCCESS_ICON_COLOR
    }
    if(dm == 2){
      data['title'] = "DM Review"
      data['color'] = SUCCESS_ICON_COLOR
    }
    if(inv == 0){
      data['title'] = "PI not Signed"
      data['color'] = ERROR_ICON_COLOR
    }
    if(inv == 1){
      data['title'] = "Partially PI Sign"
      data['color'] = PARTIAL_SUCCESS_ICON_COLOR
    }
    if(inv == 2){
      data['title'] = "PI Sign"
      data['color'] = SUCCESS_ICON_COLOR
    }
    if(softLock == 0){
      data['title'] = "Soft Lock Not Done"
      data['color'] = ERROR_ICON_COLOR
    }
    if(softLock == 2){
      data['title'] = "Soft Lock Done"
      data['color'] = SUCCESS_ICON_COLOR
    }
    return data;
  }

  getStorageData(){
    this.roles = sessionStorage.getItem('role')
    this.isLocked = sessionStorage.getItem("isLocked")
  }

  setDropdownSettings() {
    this.sitesArray = []
    this.visitsArray = []
    this.formsArray = []
    this.subjectsArray = []
    
    this.visitDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select all',
      unSelectAllText: 'Deselect all',
      itemsShowLimit: 2,
      allowSearchFilter: true
    }
    
    this.formDropdownSettings = {
      singleSelection: false,
      idField: 'formId',
      textField: 'formName',
      selectAllText: 'Select all',
      unSelectAllText: 'Deselect all',
      itemsShowLimit: 2,
      allowSearchFilter: true
    }
    
    this.statusDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select all',
      unSelectAllText: 'Deselect all',
      itemsShowLimit: 2,
      allowSearchFilter: true
    }
    
    this.subjectDropDownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'subjectId',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect',
      itemsShowLimit: 2,
      allowSearchFilter: true,
    }
    
    this.siteDropDownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect',
      itemsShowLimit: 2,
      allowSearchFilter: true,
    }

    this.logFormDropdownSetting = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect',
      itemsShowLimit: 2,
      allowSearchFilter: true,
    };
  }

  getLogFormData(){
    this.formAPI.getLogFormsData().subscribe(res=>{
      this.logFormList = res.responseObject
      this.setQueryStringData();
      this.getFormStatus()
    })
  }

  getVisitFormData(subjectId:any){
    this.visitsArray = []
    this.formAPI.getVisitFormData(subjectId).subscribe(res => {
      const visits = res.responseObject['visit']
      this.visitFormData = res.responseObject
      this.visitsList = this.extractVisits(visits)
    })
  }

  getSiteSubjectData(){
    this.siteAPI.getAllSiteSubjectData().subscribe(res => {
      this.siteSubjectData = res.responseObject
      const sites = res.responseObject['sites']
      this.sitesList = this.extractSites(sites)
      this.getLogFormData()
    })
  }

  onSelectSubject(){
    this.selectedFormIds = []
    this.selectedVisitIds = []
    this.selectedStatusId = []
    this.dataReviewForm.get('form')?.reset()
    this.dataReviewForm.get('visit')?.reset()
    this.dataReviewForm.get('status')?.reset()
    let subject:any[] = this.dataReviewForm.get("subject")?.value
    let subjectIds:any[] = []
    if(subject && subject.length > 0){
      subject.filter((res:any) => {
        subjectIds.push(res.id)
      })
    }
    this.getVisitFormData(subjectIds.join(","))
  }

  deSelectSubject(){
    this.selectedFormIds = []
    this.selectedVisitIds = []
    this.dataReviewForm.get('form')?.reset()
    this.dataReviewForm.get('visit')?.reset()
    let subject:any[] = this.dataReviewForm.get("subject")?.value
    let subjectIds:any[] = []
    if(subject && subject.length > 0){
      subject.filter((res:any) => {
        subjectIds.push(res.id)
      })
    }
    this.getVisitFormData(subjectIds.join(","))
  }

  deSelectSite(){
    this.selectedStatusId = null
    this.dataReviewForm.get('subject')?.reset()
    this.dataReviewForm.get('form')?.reset()
    this.dataReviewForm.get('status')?.reset()
    let siteData = this.dataReviewForm.get('sites')?.value
    if(siteData.length == 0){
      this.sitesList = this.extractSites(this.siteSubjectData['sites'])
    }
    this.dataReviewForm.get('form')?.reset()
  }

  onSelectVisit(){
    this.dataReviewForm.get('form')?.reset()
  }

  deSelectVisit(){
    this.selectedStatusId = null
    this.dataReviewForm.get('form')?.reset()
    this.dataReviewForm.get('status')?.reset()
    let visitData = this.dataReviewForm.get('visit')?.value
    if(visitData.length == 0){
      this.visitsList = this.extractVisits(this.visitFormData['visit'])
    }
  }

  deSelectForm(){
    this.selectedStatusId = null
    this.dataReviewForm.get('status')?.reset()
  }

  onSelectSite(){
    this.dataReviewForm.get('subject')?.reset()
  }

  populateSubjectDropdown() {
    this.dataReviewForm
    .get('site')
    ?.valueChanges.pipe(distinctUntilChanged())
    .subscribe((data) => {
      if(!data) {
        this.toastr.warning(SITE_SELECTION_ERROR_MSG)
      }      
      this.subjectsList = this.extractSubjects(data)
    })
  }

  populateFormDropdown() {
    this.dataReviewForm
    .get('visit')
    ?.valueChanges.pipe(distinctUntilChanged())
    .subscribe((data) => {
      this.formsList = this.extractForms(data)
    })
  }

  getSelectedSubject() {
    this.dataReviewForm
    .get('subject')
    ?.valueChanges.pipe(distinctUntilChanged())
    .subscribe((data) => {
      if (!Array.isArray(data)) {
        data = [data]
      }
    })
  }

  getSelectedForms() {
    this.dataReviewForm
    .get('form')
    ?.valueChanges.pipe(distinctUntilChanged())
    .subscribe((data) => {
      // this.dataReviewForm.get('form')?.patchValue(data)
      //this.populateTableData()
    })
  }

  getSelectedStatus() {
    this.dataReviewForm
    .get('status')
    ?.valueChanges.pipe(distinctUntilChanged())
    .subscribe((data) => {
      // this.dataReviewForm.get('status')?.patchValue(this.selectedStatusId)
    })
  }

  onStatusSelect(event:any){
    this.dataReviewForm.get("status")?.setValue(event.target.value) 
  }

  extractSites(sites: any): any[] {
    this.sitesArray = []
    let subjectArray: any = []
    Object.keys(sites).map((key) => {
      this.sitesArray.push({ id: key, name: sites[key].name })
      sites[key].subjects.map((key: any, value: any)=>{
        subjectArray.push(key);
      })
    })
    this.subjectsList = subjectArray;
    return this.sitesArray
  }

  extractSubjects(sites: any[]): any[] {
    this.subjectsArray = []
    sites.map(item => {
      const subjectIdKey = item.id
      const subjectDetails = this.siteSubjectData['sites'][subjectIdKey].subjects
      this.subjectsArray.push(...subjectDetails)
    })
    return this.subjectsArray
  }

  extractVisits(visits: any): any[] {
    this.visitsArray = []
    let formsArray: any = []
    Object.keys(visits).map((key) => {
      this.visitsArray.push({ id: key, name: visits[key].name })
      visits[key].forms.map((key: any, value: any)=>{
        formsArray.push(key);
      })
    })
    this.formsList = formsArray;
    return this.visitsArray
  }

  extractForms(visits: any[]): any[] {
    this.formsArray = []
    visits.map((item) => {
      if(item.id != this.defaultUuid){
        const formIdKey = item.id
        const formDetails = this.visitFormData['visit'][formIdKey].forms
        this.formsArray.push(...formDetails)
      }
    })
    return this.formsArray
  }

  validateData(data:any){
    if(data.siteId.length == 0 || data.siteId[0] == -1){
      this.toastr.warning(SITE_SELECT_MSG)
      return false
    }
    
    /*else if(data.subjectId.length == 0){
      this.toastr.warning(SUBJECT_SELECT_MSG)
      return false

    }else if(data.visitId.length == 0){
      this.toastr.warning(VISIT_SELECT_MSG)
      return false

    }else if(data.formId.length == 0){
      this.toastr.warning(FORM_SELECT_MSG)
      return false

    }*/
    
    return true
  }

  getFormStatus(){
    const { form, site, subject, visit, logForm, status } = this.dataReviewForm.value
    this.subjectApiSvc.isSubjectSelected.next(false);
    if (site && site.length) {
      this.selectedSiteIds = site.map((item: any) => Number(item.id))
    }else{
      this.selectedSiteIds = []
    }

    if (subject && subject.length) {
      this.selectedSubjectIds = subject.map((item: any) => item.id)
    }else{
      this.selectedSubjectIds = []
    }

    if (visit && visit.length) {
      this.selectedVisitIds = visit.map((item: any) => item.id)
    }else{
      if(logForm && logForm.length){
        this.selectedVisitIds = [DEFAULT_UUID];
      }else{
        this.selectedVisitIds = [];
      }
    }

    if (form && form.length) {
      this.selectedFormIds = form.map((item: any) => Number(item.formId))
    }else{
      this.selectedFormIds = []
    }
    
    if (logForm && logForm.length > 0) {
      this.selectedLogFormIds = logForm.map((item: any) => Number(item.id))
    }else{
      this.selectedLogFormIds = []
    }
    
    if(status && status.length){
      this.selectedStatusId = status.map((item: any) => Number(item.id))
    }else{
      this.selectedStatusId = []
    }

    
    if (this.isApplyClicked) {
      this.pageNo = DEFAULT_PAGE_NO;
      this.isApplyClicked= false;
    }else{
      this.isApplyClicked= false
    }
    
    this.getListData();

    let queryData = { 
      siteId: this.selectedSiteIds.join(","),
      subjectId: this.selectedSubjectIds.join(",") ,
      visitId: this.selectedVisitIds.join(","),
      formId: this.selectedFormIds.join(",")
    } 
    localStorage.setItem('queryData', JSON.stringify(queryData));
    
    this.router.navigate([],
    { 
      queryParams: 
      { 
        siteId: this.selectedSiteIds.join(","),
        subjectId: this.selectedSubjectIds.join(",") ,
        visitId: this.selectedVisitIds.join(","),
        formId: this.selectedFormIds.join(","),
        status: this.selectedStatusId.join(","),
        pageNo: this.pageNo,
        pageSize: this.pageSize
      } 
    });

  }

  setQueryStringData(){
    var data = this.queryStringData.params;

    if(JSON.stringify(data) === JSON.stringify({}))
      return;

    this.selectedSiteIds = data.siteId != undefined && data.siteId != "" ? data.siteId.split(",") : [];
    this.selectedSubjectIds = data.subjectId != undefined && data.subjectId != "" ? data.subjectId.split(",") : [];
    this.selectedVisitIds = data.visitId != undefined && data.visitId != "" ? data.visitId.split(",") : [];
    this.selectedFormIds = data.formId != undefined && data.formId != "" ? data.formId.split(",") : [];
    this.selectedStatusId = data.status != undefined && data.status != "" ? data.status.split(",") : [];

    var self = this;

    if(this.selectedSiteIds.length > 0 && this.selectedSiteIds.find(function(data:any){ return data != -1 })){
      this.dataReviewForm.controls.site.setValue(this.sitesList.filter(function(f:any){
        return self.selectedSiteIds.find(function(element:any) { return element == Number(f.id) });
      }))
    }

    if(this.selectedSubjectIds.length > 0 && this.selectedSubjectIds.find(function(data:any){ return data != -1 })){
      this.dataReviewForm.controls.subject.setValue(this.subjectsList.filter(function(f:any){
        return self.selectedSubjectIds.find(function(element:any) { return element == f.id });
      }));
    }

    if(this.selectedVisitIds.length > 0 && this.selectedVisitIds.find(function(data:any){ return data != -1 })){
      this.dataReviewForm.controls.visit.setValue(this.visitsList.filter(function(f:any){
        return self.selectedVisitIds.find(function(element:any) { return element == f.id });
      }));
    }

    if(this.selectedFormIds.length > 0 && this.selectedFormIds.find(function(data:any){ return data != -1 })){
      this.dataReviewForm.controls.form.setValue(this.formsList.filter(function(f:any){
        return self.selectedFormIds.find(function(element:any) { return element == Number(f.formId) });
      }));
    }

    if(this.selectedFormIds.length > 0 && this.selectedFormIds.find(function(data:any){ return data != -1 })){
      this.dataReviewForm.controls.logForm.setValue(this.logFormList.filter(function(f:any){
        return self.selectedFormIds.find(function(element:any) { return element == Number(f.id) });
      }));
    }

    if(this.selectedStatusId.length > 0 && this.selectedStatusId.find(function(data:any){ return data != -1 })){
      this.dataReviewForm.controls.status.setValue(this.statusList.filter(function(f:any){
        return self.selectedStatusId.find(function(element:any) { return element == Number(f.id) });
      }));
    }

    // this.getListData();
  }

  getListData(){
    this.selectedFormIds.push(...this.selectedLogFormIds)
    
    const requestBody = {
      siteId: this.selectedSiteIds,
      subjectId: this.selectedSubjectIds,
      visitId: this.selectedVisitIds,
      formId: this.selectedFormIds,
      status: this.selectedStatusId,
      pageNo: this.pageNo,
      pageSize: this.pageSize,
      roleId:this.roles
    }
    
    this.searchLoading = true;
    this.reviewAPI.getReviewerTableData(this.selectedSiteIds,this.selectedSubjectIds,this.selectedVisitIds,this.selectedFormIds,this.selectedStatusId,this.pageNo,this.pageSize).subscribe((response) => {
      this.checkedList = []
      if(response.status == -999){
        this.toastr.warning(DATA_NOT_AVAILABLE_MSG)
        this.tableData = []
      }else{
        this.tableData = response.responseObject.responseData
        for (let index = 0; index < this.tableData.length; index++) {
          this.tableData[index].crc = this.tableData[index].crc_status
          this.tableData[index].cra = this.tableData[index].cra_status
          this.tableData[index].dm = this.tableData[index].dm_status
          // this.tableData[index].ml = this.tableData[index].ml_status
          if((this.tableData[index].softLock == 0 && this.tableData[index].crc_status == 2) || (this.tableData[index].softLock == 2 && this.tableData[index].crc_status == 1) || (this.tableData[index].softLock == 0 && this.tableData[index].crc_status == 1)){
            this.tableData[index].checkData = false
            this.checkedList[index] = false
          }

        }
        let total:any = Number(response.responseObject.totalCount)
        this.totalPages = total
      }
      this.searchLoading = false;
    },err=>{
      this.toastr.error(DATA_NOT_AVAILABLE_MSG)
      this.searchLoading = false;
    })
  }

  fillUpForm(tableRow: any) {
    const { siteId, siteName, subjectId, subjectAutoId, visitId,visitName, 
    formId, formName, crc_status, cra_status, 
    // ml_status, 
    dm_status, investigator_status } = tableRow
    
    let logFormId = this.dataReviewForm.get("logForm")?.value
    
    if (subjectId) {
      let queryParameters:any = {
        formName:formName,
        visitId:visitId,visitName:visitName,
        subjectId:subjectId,subjectAutoId:subjectAutoId,
        siteId:siteId,siteName:siteName,
        crc_status:crc_status,cra_status:cra_status,
        // ml_status:ml_status,
        dm_status:dm_status
      }
      if(logFormId && logFormId.length > 0 ){
        queryParameters['logFormId']= formId
      }else{
        queryParameters['formId']= formId
  
      }
      if(investigator_status){
        queryParameters['investigator_status'] = investigator_status
        this.router.navigate(['/layout/data-entry-review'],{queryParams:queryParameters})

      }
      this.router.navigate(['/layout/data-entry-review'],{queryParams:queryParameters})

    } else {
      this.toastr.warning(SUBJECT_SELECTION_MSG)
    }
  }

  fillData(){

    const { form, site, subject, visit, logForm } = this.dataReviewForm.value
    
    if(site && site.length > 2 || site == null){
      this.toastr.warning(SITE_ANY_ONE_SELECT_MSG)
    }else if(subject && subject.length > 2 || subject == null){
      this.toastr.warning(SUBJECT_ANY_ONE_SELECT_MSG)
    }else if(visit && visit.length > 2 || visit == null){
      this.toastr.warning(VISIT_ANY_ONE_SELECT_MSG)
    }else if(form && form.length > 2 || form == null){
      this.toastr.warning(FORM_ANY_ONE_SELECT_MSG)
    }else{
      let siteId:any;let siteName:any
      let subjectId:any;let subjectName:any
      let visitId:any;let visitName:any
      let formId:any;let formName:any
      let logFormId:any;let logFormName:any
      site.map((item:any)=>{
        siteId = item.id
        siteName = item.name
      })
      
      subject.map((item:any)=>{
        subjectId = item.id
        subjectName = item.subjectId
      })

      visit.map((item:any)=>{
        visitId = item.id
        visitName = item.name
      })

      form.map((item:any)=>{
        formId = item.formId
        formName = item.formName
      })
      if(logForm && logForm.length){
        logForm.map((item:any)=>{
          logFormId = item.id
          logFormName = item.name
        })
      }

      if(logFormId != null){
        visitId = this.defaultUuid,
        visitName = DEFAULT_VISIT_NAME
      }

      this.router.navigate(['/layout/data-entry'],
      {
        queryParams:{
          siteId:siteId,
          siteName:siteName,
          subjectId:subjectId,
          subjectName:subjectName,
          visitId:visitId,
          visitName:visitName,
          formId:formId,
          formName:formName,
          logFormId:logFormId,
          logFormName:logFormName
        }
      })
    }
  }

  isAllSelected(event:any,row:any,index:any) {
    this.isChecked = false
    this.checkedList[index] = event.target.checked
    this.masterSelected = Object.keys(this.checkedList).every((key) => {
      return this.checkedList[Number(key)] === true
    })
  }

  checkUncheckAll(event: any) {
    this.isChecked = false
    this.masterSelected = event.target.checked
    Object.keys(this.checkedList).map((key) => {
        this.checkedList[Number(key)] = this.masterSelected
    })
    if(this.masterSelected){
      this.isChecked = false
    }else{
      this.isChecked = true
    }
  }

  fillFormReviewData(){
    Object.keys(this.checkedList).forEach((key:any)=>{
      if(this.checkedList[key] == true){
        for (let index = 0; index < this.tableData.length; index++) {
          if(index == key){
            const formData = {
              formId:Number(this.tableData[index].formId),
              // siteId:this.tableData[index].siteId,
              subjectId:this.tableData[index].subjectId,
              visitId:this.tableData[index].visitId
            }
            this.formReviewData.push(formData)
          }
        }
      }
    })
  }

  filterStatusData(status:any){
    this.formReviewData = []    
    Object.keys(this.checkedList).filter((key:any) => {
      if(this.checkedList[key] == true){
        if(this.tableData){
          for (let j = 0; j < this.tableData.length; j++) {
            if(this.roles == Role.CRA){
              if(j == key && this.tableData[j].cra != status){
                const formData = {
                  formId:Number(this.tableData[j].formId),
                  // siteId:this.tableData[j].siteId,
                  subjectId:this.tableData[j].subjectId,
                  visitId:this.tableData[j].visitId
                }
                this.formReviewData.push(formData)
              }
            }else if(this.roles == Role.DM){
              if(j == key && this.tableData[j].dm != status){
                const formData = {
                  formId:Number(this.tableData[j].formId),
                  // siteId:this.tableData[j].siteId,
                  subjectId:this.tableData[j].subjectId,
                  visitId:this.tableData[j].visitId
                }
                this.formReviewData.push(formData)
              }
            }else if(this.roles == Role.Investigator){
              if(j == key && this.tableData[j].inv != status){
                const formData = {
                  formId:Number(this.tableData[j].formId),
                  // siteId:this.tableData[j].siteId,
                  subjectId:this.tableData[j].subjectId,
                  visitId:this.tableData[j].visitId
                }
                this.formReviewData.push(formData)
              }
            }
          }
        }
      }
    })    
  }

  selectAllData(event:any){
    this.formReviewData = []
    if(this.tableData){
      for (let index = 0; index < this.tableData.length; index++) {
        if(event.checked){
          this.tableData[index].checkData = true
          this.checkedData(event,this.tableData[index].siteId,this.tableData[index].subjectId,
            this.tableData[index].visitId,this.tableData[index].formId)
          // var clean = this.tableData.filter((arr:any, index:any, self:any) =>
          // index === self.findIndex((t:any) => ( t.formId === arr.formId )))
          }else {
            this.tableData[index].checkData = false
            this.formReviewData = []
          }
        }
      }
  }

  checkedData(event:any,site?:any,subject?:any,visit?:any,form?:any){
    this.isChecked = false
    const formsData = {
      siteId:site,
      subjectId:subject,
      visitId:visit,
      formId:form
    }
    if(event.checked){

      this.formReviewData.push(formsData)
    }else{
      const index = this.formReviewData.findIndex(list => list.formId == form)
      this.formReviewData.splice(index, 1)
    }
  }

  reviewQuery(){
    this.fillFormReviewData()
    this.updateFormStatus.get('status')?.setValue(STATUS_DATA[2].id)
    this.updateFormStatus.get('forms')?.setValue(this.formReviewData)
    // this.updateFormStatus.get('roleId')?.setValue(this.roles)
    this.reviewLoading = true
    this.review.postFormStatus(this.updateFormStatus.value).subscribe(res =>{
      if(res.status != 0){
        this.toastr.error(res.message)
      }else{
        if(res.responseObject){
          this.toastr.warning(FORM_REVIEW_NOT_REVIEWED_MSG+""+res.responseObject)
        }else{
          this.toastr.success(CRA_REVIEW_SUCCESS_MSG)
        }
      }
      this.formReviewData = []
      this.isChecked = true
      this.masterSelected = false
      this.getFormStatus()
      this.reviewLoading = false
    },err=>{
      this.toastr.error(DATA_NOT_SELECT_MSG)
      this.reviewLoading = false
    })
  }
  unReviewQuery(){
    this.fillFormReviewData()
    this.updateFormStatus.get('status')?.setValue(STATUS_DATA[0].id)
    this.updateFormStatus.get('forms')?.setValue(this.formReviewData)
    // this.updateFormStatus.get('roleId')?.setValue(this.roles)
    this.unReviewLoading = true
    this.review.postFormStatus(this.updateFormStatus.value).subscribe(res =>{
      if(res.status != 0){
        this.toastr.error(res.message)
      }else{
        if(res.responseObject){
          this.toastr.warning(FORM_REVIEW_NOT_REVIEWED_MSG+""+res.responseObject)
        }else{
          this.toastr.success(CRA_REVIEW_SUCCESS_MSG)

        }
      }
      this.formReviewData = []
      this.isChecked = true
      this.masterSelected = false
      this.getFormStatus()
      this.unReviewLoading = false
    },err=>{
      this.toastr.error(DATA_NOT_SELECT_MSG)
      this.unReviewLoading = false
    })
  }

  //Investigator Sign-off
  signOff(status:any){
    this.openComponent(status)
  }
  
  openComponent(status:any) {
    this.fillFormReviewData()
    let dropModal = this.modalService.open(AddSignatureComponent)
    this.updateFormStatus.get('status')?.setValue(status)
    this.updateFormStatus.get('forms')?.setValue(this.formReviewData)
    // this.updateFormStatus.get('roleId')?.setValue(this.roles)
    dropModal.componentInstance.mainData = this.updateFormStatus.value
    dropModal.componentInstance.isFormLevel = true
    dropModal.componentInstance.isFromReview = true
    localStorage.setItem("signForm",JSON.stringify(this.formReviewData))
    dropModal.dismissed.subscribe(()=>{
      this.getFormStatus()
      this.masterSelected = false
      this.isChecked = true
    })
    
  }

  onChangePagination(){
    this.masterSelected = false
    this.checkedList = []
    this.pageNo = this.page - 1
    this.getFormStatus()
  }

  selectPage(page: string, pageLength: number) {
    this.masterSelected = false
    this.checkedList = []
    // this.page = parseInt(page, 10) || 1;
    this.pageNo = +page - 1

    if(pageLength<this.pageNo){
      this.pageNo = pageLength;
    }

    this.getFormStatus()

	}

	formatInput(input: HTMLInputElement, pageLength: number) {
    this.masterSelected = false
    this.checkedList = []
    const FILTER_PAG_REGEX = /[^0-9]/g;
		input.value = input.value.replace(FILTER_PAG_REGEX, '');
    if (pageLength <= Number(input.value)) {
      input.value = String(pageLength);
    }
    (pageLength <= +input.value) ? input.value: pageLength
	}

  changeStatus(role:any,status:any){
    this.fillFormReviewData()
    this.updateFormStatus.get('status')?.setValue(status)
    // this.updateFormStatus.get('roleId')?.setValue(this.roles)
    if(status == 2){
      this.reviewLoading = true
    }else{
      this.unReviewLoading = true
    }
    
    if(this.formReviewData.length > 0){
      this.filterStatusData(status)
      if(this.formReviewData.length > 0){
        this.updateFormStatus.get('forms')?.setValue(this.formReviewData)
        this.review.postFormStatus(this.updateFormStatus.value).subscribe(res =>{
          if(res.status != 0){
            this.toastr.error(res.message)
          }else{
            let response:string = res.responseObject
            if(response.length > 0){
              this.toastr.warning(FORM_REVIEW_NOT_REVIEWED_MSG+""+res.responseObject)
            }else{
              if(role == Role.CRA){
                if(status == 2){
                  this.toastr.success(CRA_REVIEW_SUCCESS_MSG)
                }else{
                  this.toastr.success(CRA_UNREVIEW_SUCCESS_MSG)
                }
              }else if(role == Role.DM){
                if(status == 2){
                  this.toastr.success(DM_REVIEW_SUCCESS_MSG)
                }else{
                  this.toastr.success(DM_UNREVIEW_SUCCESS_MSG)
                }
              }
            }
          }
          this.formReviewData = []
          this.isChecked = true
          this.masterSelected = false
          if(status == 2){
            this.reviewLoading = false
          }else{
            this.unReviewLoading = false
          }
          this.getFormStatus()
        },err=>{
          if(status == 2){
            this.reviewLoading = false
          }else{
            this.unReviewLoading = false
          }
          this.toastr.error(DATA_NOT_SELECT_MSG)
        })
      }else{
        if(role == Role.CRA){
          if(status == 2){
            this.toastr.warning(SDV_FORM_LEVEL)
          }else{
            this.toastr.warning(NON_SDV_FORM_LEVEL)
          }
        }else if(role == Role.DM){
          if(status == 2){
            this.toastr.warning(REVIEW_DM_FORM_LEVEL)
          }else{
            this.toastr.warning(UNREVIEW_DM_FORM_LEVEL)
          }
        }
        this.formReviewData = []
        this.isChecked = true
        this.masterSelected = false
        if(status == 2){
          this.reviewLoading = false
        }else{
          this.unReviewLoading = false
        }
        this.getFormStatus()
      }
    }else{
      this.toastr.error(DATA_NOT_SELECT_MSG)
      this.formReviewData = []
      this.getFormStatus()
      this.reviewLoading = false
      this.isChecked = true
      this.masterSelected = false
    }
  }
}
