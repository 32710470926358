import { NgxSmoothDnDModule } from 'ngx-smooth-dnd'
import { DragAndDropModule } from 'angular-draggable-droppable'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HttpClientModule, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { LoginComponent } from './components/login/login.component'
import { RolesAndStudyComponent } from './components/roles-and-study/roles-and-study.component'
import { ValidationFormulaComponent } from './components/validation-formula/validation-formula.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { FormulaPopupComponent } from './components/popup/formula-popup/formula-popup.component'
import { AddValidatonPopupComponent } from './components/popup/add-validaton-popup/add-validaton-popup.component'
import { FormPopupComponent } from './components/form-popup/form-popup.component'
import { AuthGuardService } from './services/auth-guard.service'
import { DataEntryReviewComponent } from './components/data-entry-review/data-entry-review.component'
import { QueryRaisedComponent } from './components/popup/query-raised/query-raised.component'
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component'
import { ToastrModule } from 'ngx-toastr'
import { AuthService } from './services/auth.service'
import { ChangePasswordComponent } from './components/popup/change-password/change-password.component'
import { AddSignatureComponent } from './components/popup/add-signature/add-signature.component';
import { WarnIfValidationComponent } from './components/popup/warn-if-validation/warn-if-validation.component';
import { NoteFieldAuditLogPopupComponent } from './components/popup/note-field-audit-log-popup/note-field-audit-log-popup.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker'
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { QueryRaisingPopupComponent } from './components/popup/query-raising-popup/query-raising-popup.component';
import { ReasonPopupComponent } from './components/popup/reason-popup/reason-popup.component';
import { TherapeuticComponent } from './components/therapeutic/therapeutic.component';
import { IndicationComponent } from './components/indication/indication.component';
import { PhaseComponent } from './components/phase/phase.component';
import { UnitsComponent } from './components/units/units.component';
import { FormDetailPopupComponent } from './components/popup/form-detail-popup/form-detail-popup.component';
import { SubjectCommentPopupComponent } from './components/popup/subject-comment-popup/subject-comment-popup.component';
import { StudyLockingPopupComponent } from './components/popup/study-locking-popup/study-locking-popup.component';
import { UnscheduleVisitPopupComponent } from './components/popup/unschedule-visit-popup/unschedule-visit-popup.component';
import { UnscheduleVisitListPopupComponent } from './components/popup/unschedule-visit-list-popup/unschedule-visit-list-popup.component';
import { ImportPopupComponent } from './components/popup/import-popup/import-popup.component';
import { CaptchaModule } from "./components/shared/captcha/captcha.module";
import { LoginPopupComponent } from './components/popup/login-popup/login-popup.component';
import { ReportlogComponent } from './components/reportlog/reportlog.component'
import { CustomReportsComponent } from './components/custom-reports/custom-reports.component';
import { CustomReportDesignComponent } from './components/custom-report-design/custom-report-design.component';
import { RandomizationModule } from './components/Randomization/randomization.module'

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RolesAndStudyComponent,
        ValidationFormulaComponent,
        ChangePasswordComponent,
        FormulaPopupComponent,
        AddValidatonPopupComponent,
        FormPopupComponent,
        DataEntryReviewComponent,
        QueryRaisedComponent,
        ForgotPasswordComponent,
        AddSignatureComponent,
        WarnIfValidationComponent,
        NoteFieldAuditLogPopupComponent,
        QueryRaisingPopupComponent,
        ReasonPopupComponent,
        TherapeuticComponent,
        IndicationComponent,
        PhaseComponent,
        UnitsComponent,
        FormDetailPopupComponent,
        SubjectCommentPopupComponent,
        StudyLockingPopupComponent,
        UnscheduleVisitPopupComponent,
        UnscheduleVisitListPopupComponent,
        ImportPopupComponent,
        LoginPopupComponent,
        ReportlogComponent,
        CustomReportsComponent,
        CustomReportDesignComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthService,
            multi: true
        },
        AuthGuardService
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        DragDropModule,
        DragAndDropModule,
        NgxSmoothDnDModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgbModule,
        BrowserAnimationsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        ToastrModule.forRoot({
            progressBar: true,
            progressAnimation: 'increasing',
            closeButton: true,
            newestOnTop: true
        }),
        NgMultiSelectDropDownModule.forRoot(),
        MatSlideToggleModule,
        CaptchaModule,
        RandomizationModule
    ]
})
export class AppModule {}
