import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, Observable, of } from 'rxjs';
import { baseURL, VERSION } from 'src/app/constant/constant';
import { getTimeZoneOffset } from 'src/app/constant/globalFunction';

@Injectable({
  providedIn: 'root'
})

export class RandomizationService {

    constructor(private http: HttpClient) {
    }

    getRandomizationFormData = (option: any): Observable<any> => {
      const url = `${baseURL}randomizations/${VERSION}?subjectId=${option?.subjectId}&visitId=${option?.visitId}&formId=${option?.formId}`;
      return this.http?.post(url, {});
    }

    getRandomizationStatus = (): Observable<any> => {
      const url = `${baseURL}randomizations/v1/status`;
      return this.http?.get(url);
    } 

    downloadSampleFile():Observable<any>{
      return this.http.get(`${baseURL}randomizations/${VERSION}/download`)
    }

    getRandomizationByteArray(studyId:any): Observable<any> {
      let headers = new HttpHeaders().set('Timezone', getTimeZoneOffset());
      return this.http.get(`${baseURL}randomizations/${VERSION}/report?studyId=${studyId}`, { headers: headers });
    }
}