<div class="modal-content">
	<div class="modal-header">
		<h3 class="modal-title">
			Soft Lock <span *ngIf="rowData.subjectId != null">({{rowData.subjectId}})</span>
		</h3>
		<button type="button" class="btn-close" (click)="closeModal()"></button>
	</div>
	<div class="modal-body">
        <ul ngbNav #nav="ngbNav" class="nav-tabs" (activeIdChange)="onTabsChange($event)">
            <li ngbNavItem domId="Visit">
                <a ngbNavLink><b>Visit</b></a>
                <ng-template ngbNavContent><br>
                    <div class="card text-black msgDesign mb-3" *ngIf="responseData.length > 0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm col-md col-lg col-xl">
                                    <span class="card-title">Operation not performed for following subject</span>
                                </div>
                                <div class="col-sm col-md col-lg col-xl text-end">
                                    <button class="control-buttons" (click)="responseData = []"><span class="btn-close"></span></button>
                                </div>
                            </div>
                            <table class="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th>Subject Id</th>
                                        <th>Status</th>
                                    </tr>
                                    <tr *ngFor="let item of responseData">
                                        <td>{{item.subjectId}}</td>
                                        <td>{{item.status}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row" *ngIf="roles == 4">
                        <div class="col-sm col-md col-lg col-xl">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" (change)="checkUncheckAll($event)" [checked]="masterSelected">
                                <label class="form-check-label"><b>Select All</b></label>
                            </div>
                        </div>
                        <div class="col-sm col-md col-lg col-xl text-end">
                            <button class="btn btn-outline-primary" (click)="setStatus(2,null,true)">
                                <b>
                                    Lock 
                                    <!-- <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> -->
                                </b>
                            </button>&nbsp;
                            <button class="btn btn-outline-danger" (click)="setStatus(0,null,true)" [disabled]="rowData.isLocked">
                                <b>
                                    Unlock 
                                    <!-- <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> -->
                                </b>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="tableData.length; else noData">
                        <div class="table-responsive">
                            <table class="table table-inverse table-light">
                                <thead class="thead-inverse table-light">
                                    <tr>
                                        <th *ngIf="roles == 4">#</th>
                                        <th>Sr. No.</th>
                                        <th>Visit Name</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of tableData;let i = index">
                                        <td *ngIf="roles == 4"><input class="form-check-input" type="checkbox" (change)="isAllSelected($event,item)" [checked]="checkedList[item.id]"></td>
                                        <td>{{i+1}}</td>
                                        <td>{{item.name}}</td>
                                        <td *ngIf="roles != 4">
                                            <span class="control-buttons" *ngIf="item.isLocked">
                                                <span class="lockIcon" [style.color]="successIconColor"></span>
                                            </span>
                                            <span class="control-buttons" *ngIf="item.isLocked == undefined || item.isLocked == false">
                                                <span class="unLockIcon" [style.color]="errorIconColor"></span>
                                            </span>
                                        </td>
                                        <td *ngIf="roles == 4">
                                            <button class="control-buttons" *ngIf="item.isLocked" (click)="setStatus(0,item,false)">
                                                <span class="lockIcon" [style.color]="successIconColor"></span>
                                            </button>
                                            <button class="control-buttons" *ngIf="item.isLocked == undefined || item.isLocked == false" (click)="setStatus(2,item,false)">
                                                <span class="unLockIcon" [style.color]="errorIconColor"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <ng-template #noData>
                        <h5>No Data Available!</h5>
                    </ng-template>
                </ng-template>
            </li>
            <li ngbNavItem domId="Log Forms">
                <a ngbNavLink><b>Log Forms</b></a>
                <ng-template ngbNavContent><br>
                    <div class="row" *ngIf="roles == 4">
                        <div class="col-sm col-md col-lg col-xl">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" (change)="checkUncheckAll($event)" [checked]="masterSelected">
                                <label class="form-check-label"><b>Select All</b></label>
                            </div>
                        </div>
                        <div class="col-sm col-md col-lg col-xl text-end">
                            <button class="btn btn-outline-primary" (click)="setStatus(2,null,true)">
                                <b>
                                    Lock 
                                    <!-- <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> -->
                                </b>
                            </button>&nbsp;
                            <button class="btn btn-outline-danger" (click)="setStatus(0,null,true)" [disabled]="rowData.isLocked">
                                <b>
                                    Unlock 
                                    <!-- <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> -->
                                </b>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="logFormData.length; else noData">
                        <div class="table-responsive">
                            <table class="table table-inverse table-light">
                                <thead class="thead-inverse table-light">
                                    <tr>
                                        <th *ngIf="roles == 4">#</th>
                                        <th>Sr. No.</th>
                                        <th>Form Name</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of logFormData;let i = index">
                                        <td *ngIf="roles == 4"><input class="form-check-input" type="checkbox" (change)="isAllSelected($event,item)" [checked]="checkedList[item.id]"></td>
                                        <td>{{i+1}}</td>
                                        <td>{{item.label}}</td>
                                        <td *ngIf="roles != 4">
                                            <span class="control-buttons" *ngIf="item.isLocked">
                                                <span class="lockIcon" [style.color]="successIconColor"></span>
                                            </span>
                                            <span class="control-buttons" *ngIf="item.isLocked == undefined || item.isLocked == false">
                                                <span class="unLockIcon" [style.color]="errorIconColor"></span>
                                            </span>
                                        </td>
                                        <td *ngIf="roles == 4">
                                            <button class="control-buttons" *ngIf="item.isLocked" (click)="setStatus(0,item,false)">
                                                <span class="lockIcon" [style.color]="successIconColor"></span>
                                            </button>
                                            <button class="control-buttons" *ngIf="item.isLocked == undefined || item.isLocked == false" (click)="setStatus(2,item,false)">
                                                <span class="unLockIcon" [style.color]="errorIconColor"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <ng-template #noData>
                        <h5>No Data Available!</h5>
                    </ng-template>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-danger" (click)="closeModal()">
            <b>
                Close
            </b>
        </button>
	</div>
</div>