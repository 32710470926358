import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class EventHandlerService {

    onClickOnSubjectId: EventEmitter<any>;

    constructor() {
        this.onClickOnSubjectId = new EventEmitter<any>();
    }

    emitEventOnClickOnSubjectId = (eventData: any): void => {
        this.onClickOnSubjectId.emit(eventData);
    }
}