<div class="modal-content">
    <form [formGroup]="randomizationForm">
      <div class="modal-header">
        <h5 class="modal-title">
            {{showComponentName}} Field
        </h5>
        <button class="btn-close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">

        <!-- Randomization Number Label -->
        <div class="form-floating mb-3">
          <input type="text" 
                class="form-control" 
                formControlName="randomizationNumberLabel" 
                placeholder="hello"
                id="randomizationNumberLabel" 
                name="randomizationNumberLabel" 
                max="500" 
                maxlength="500" 
                required 
                autocomplete="off">
          <label for="randomizationNumberLabel"><b>Number</b></label>
          <i><b>Note:</b> Maximium character allowed 500</i>
        </div>

        <!-- Randomization Arm Label -->
        <div class="form-floating mb-3">
          <input type="text" 
                class="form-control" 
                placeholder="randomizationArmLabel"
                id="randomizationArmLabel" 
                formControlName="randomizationArmLabel" 
                name="randomizationArmLabel" 
                required
                maxlength="500" 
                max="500" 
                autocomplete="off">
          <label for="randomizationArmLabel"><b>Arm</b></label>
          <i><b>Note:</b> Maximium character allowed 500</i>
        </div>

        <!-- Randomization Date Label -->
        <div class="form-floating mb-3">
          <input type="text" 
                class="form-control" 
                placeholder="randomizationDateLabel"
                id="randomizationDateLabel" 
                formControlName="randomizationDateLabel" 
                name="randomizationDateLabel"
                required 
                maxlength="250" 
                max="250" 
                autocomplete="off">
          <label for="randomizationDateLabel"><b>Date</b></label>
          <i><b>Note:</b> Maximium character allowed 250</i>
        </div>    
    </div>
    <div class="modal-footer">
      <button type="button" 
            class="btn btn-outline-danger" 
            (click)="closeModal()">
        <b>Cancel</b>
      </button>
      <button type="button" 
            class="btn btn-outline-success" 
            [hidden]="roleId == readOnlyRoleId" 
            (click)="onSaveRandomizationFields()">
        <b>
          {{editFormId && editFieldId ? 'Update' : 'Save'}}
        </b>
      </button>
      <!-- <button class="btn btn-outline-primary" [hidden]="roles == readOnly" *ngIf="isEdit && roles == 1" (click)="updateData()" [disabled]="loading">
        <b>
          Update <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
        </b>
      </button> -->
    </div>
  </form>
  