//Title Messages
export const DASHOARD = "Dashboard";
export const STUDY = "Studies";
export const VISIT = "Visits";
export const FORM = "Forms";
export const SITE = "Sites";
export const USER = "Users";
export const SUBJECT = "Subjects";
export const SUBJECT_MATRIX = "Subject Matrix";
export const AUDITLOG = "Auditlog";
export const USER_LOG = "User log";
export const REPORT = "Reports";
export const EXCEL_REPORT = "Excel Reports";
export const CUSTOM_REPORT = "Custom Report";
export const PDF_REPORT = "PDF Reports";
export const QUERY = "Queries";
export const DATA_ENTRY = "Data Entry";
export const DATA_REVIEW = "Data Review";
// export const DATA_MONITOR = "Data Monitor";
export const DATA_MANAGER = "Data Manager";
export const READ_ONLY = "Data Read Only";
export const SIGN_FORM = "Sign Form";
export const FORM_CREATION = "Form Creation";
export const FORM_PREVIEW = "Form Preview";
export const FORM_VALIDATION_FORMULA = "Form Validation & Formulas";
export const LOG_FORM_PAGE = "Log Form Page";
export const DESIGN = 'Design';
export const DATA_POINT = 'Data Point';
export const REPORT_LOGS = 'Report Logs';
export const REVIEW_LOGS = 'Review';
export const THERAPEUTIC = 'Therapeutic';
export const UNITS = 'Units';
export const PHASE = 'Phase';
export const INDICATION = 'Indication';
export const FORMULA = 'Formula';
export const MASTER = 'Master';
export const VALIDATION = 'Validation';
export const STUDY_LOCKING = 'Study Locking';
export const VISIT_LOCKING = 'Visit Locking';
export const SOFT_LOCK = 'Soft Lock';
export const IMPORT_TITLE = 'Import';

//Common Messages
export const CANCEL_MSG = "Cancel Operation";
export const HARD_DELETE_MSG = "Are you sure you want to hard delete this form?";
export const DELETE_MSG = "Are you sure you want to delete?";
export const IMAGE_SUPPORT_MSG = "Only images are supported.";
export const IMAGE_SIZE_MSG = "Image Size is Big \n File must be less than 1 mb!";
export const IMAGE_NOT_FOUND_MSG = "Only images are supported.";
export const EMAIL_ERROR_MSG = "Email is required";
export const EMAIL_DOES_NOT_EXIST_MSG = "Email does not exists";
export const CONTACT_NUMBER_ERROR_MSG = "Contact Number is required";
export const REQUIRED_MSG = "Data is Required";
export const MINIMUM_MSG = "Minimum Length";
export const MAXIMUM_MSG = "Maximum Length";
export const MINIMUM_VALUE_MSG = "Minimum Value";
export const MAXIMUM_VALUE_MSG = "Maximum Value";
export const REASON_MSG = "Please enter reason for updating the data.";
export const LOCK_UNLOCK_REASON_MSG = "Reason is required";
export const LOCK_UNLOCK_USERNAME_MSG = "User Name is required";
export const LOCK_UNLOCK_PASSWORD_MSG = "Password is required";
export const SITE_ANY_ONE_SELECT_MSG = "Select only 1 site";
export const SUBJECT_ANY_ONE_SELECT_MSG = "Atleast 1 subject should be selected for Data Entry";
export const VISIT_ANY_ONE_SELECT_MSG = "Select only 1 visit";
export const FORM_ANY_ONE_SELECT_MSG = "Select only 1 form";
export const LOG_FORM_ANY_ONE_SELECT_MSG = "Select only 1 log form";
export const FORM_LOGFORM_NOT_SELECT_MSG = "Select either form or logform";
export const SELECT_FORM_MSG = "Select form";
export const DATA_NOT_SELECT_MSG = "Data not selected";
export const DATA_NOT_AVAILABLE_MSG = "Data not available";
export const CAPTCHA_MSG = "Enter Valid Captcha";
export const SOMETHING_WRONG = "Something is wrong";

export const VALIDATION_ACTION_REQUIRED_MSG = "Validation : Action is required";
export const VALIDATION_MESSAGE_REQUIRED_MSG = "Validation : Message is required";

export const VALIDATION_VISIT_REQUIRED_MSG = "Validation : Visit is required";
export const VALIDATION_FORM_REQUIRED_MSG = "Validation : Form is required";
export const VALIDATION_FIELD_REQUIRED_MSG = "Validation : Field is required";
export const VALIDATION_COLUMN_REQUIRED_MSG = "Validation : Column is required";
export const VALIDATION_OPTION_REQUIRED_MSG = "Validation : Option is required";
export const VALIDATION_VISIT_DAY_REQUIRED_MSG = "Validation : Visit day is required";
export const VALIDATION_VISIT_DAY_MAX_LIMIT_MSG = "Validation : Enter less than 9999 days";
export const VALIDATION_WINDOW_PERIOD_REQUIRED_MSG = "Validation : Window period is required";
export const VALIDATION_MULTIROW_COLUMN_REQUIRED_MSG = "Validation : MultiRow Column is required";

export const VALIDATION_OPERATOR_REQUIRED_MSG = "Validation : Operator is required";
export const VALIDATION_FIELD_VALUE_REQUIRED_MSG = "Validation : Value is required";
export const VALIDATION_JSON_FIELD_VALUE_REQUIRED_MSG = "Validation : Value is required";
export const VALIDATION_RULE_REQUIRED_MSG = "Validation : Add validation to save the validate data"

export const DEST_VALIDATION_VISIT_REQUIRED_MSG = "Validation : Dest Visit is required"
export const DEST_VALIDATION_FORM_REQUIRED_MSG = "Validation : Dest Form is required"
export const DEST_VALIDATION_FIELD_REQUIRED_MSG = "Validation : Dest Field is required"
export const DEST_VALIDATION_COLUMN_REQUIRED_MSG = "Validation : Dest Column is required"

export const LOGMAPPING_ACTION_REQUIRED_MSG = "LogMapping : Action is required";
export const LOGMAPPING_MESSAGE_REQUIRED_MSG = "LogMapping : Message is required";

export const LOGMAPPING_VISIT_REQUIRED_MSG = "LogMapping : Visit is required";
export const LOGMAPPING_FORM_REQUIRED_MSG = "LogMapping : Form is required";
export const LOGMAPPING_FIELD_REQUIRED_MSG = "LogMapping : Field is required";

export const LOGMAPPING_OPERATOR_REQUIRED_MSG = "LogMapping : Operator is required";
export const LOGMAPPING_FIELD_VALUE_REQUIRED_MSG = "LogMapping : Value is required";
export const LOGMAPPING_JSON_FIELD_VALUE_REQUIRED_MSG = "LogMapping : Value is required";
export const LOGMAPPING_RULE_REQUIRED_MSG = "LogMapping : Add validation to save the validate data"

export const DEST_LOG_MAPPING_VISIT_REQUIRED_MSG = "LogMapping : Dest Visit is required"
export const DEST_LOG_MAPPING_FORM_REQUIRED_MSG = "LogMapping : Dest Form is required"
export const DEST_LOG_MAPPING_FIELD_REQUIRED_MSG = "LogMapping : Dest Field is required"

export const VALIDATION_STATUS_CONFIRMATION = "Are you you want to change the validation status?"
export const VALIDATION_DELETE_CONFIRMATION = "Are you sure you want to delete the validation?"
export const LOGMAPPING_STATUS_CONFIRMATION = "Are you you want to change the log mapping status?"
export const LOGMAPPING_DELETE_CONFIRMATION = "Are you sure you want to delete the log mapping?"

//Study Messages
export const STUDY_REQUIRED_MSG = "Study is required";
export const LOCKED_STUDY_MSG = "Are you sure you want to Locked this study?";
export const UNLOCKED_STUDY_MSG = "Are you sure you want to Unlocked this study?";
export const STUDY_NAME_ERROR_MSG = "Study Name is required";
export const STUDY_CODE_ERROR_MSG = "Study Code is required";
export const STUDY_PROTOCOL_NUMBER_ERROR_MSG = "Study Protocol Number is required";
export const STUDY_PROTOCOL_TITLE_ERROR_MSG = "Study Protocol Title is required";
export const THERAPEUTIC_ERROR_MSG = "Therapeutic is required";
export const INDICATION_ERROR_MSG = "Indication is required";
export const PHASE_ERROR_MSG = "Phase is required";
export const DATE_FORMAT_ERROR_MSG = "Date Format is required";
export const START_DATE_ERROR_MSG = "Start Date is required";
export const END_DATE_ERROR_MSG = "End Date is required";
export const STUDY_EXPORT_TO_PRODUCTION_MSG = "Are you sure to push {studyName} to production?";
export const FORMULA_EXPORT_TO_PRODUCTION_MSG = "Are you sure to push \"{formulaName}\" to production?";
export const VALIDATION_EXPORT_TO_PRODUCTION_MSG = "Are you sure to push \"{validationName}\" to production?";
export const EXPORT_TO_PRODUCTION_CONFIRM_MSG = "Are you sure to push Selected {Name} to production?";
export const IMPORT_QUERIES_MSG = "File data sorted by query Id and Date?";
export const IMPORT_QUERIES_PROMPT_ANSWER_MSG = "Confirm";
export const IMPORT_QUERIES_PROMPT_MSG = "Type 'Confirm' for confirmation";
export const IMPORT_QUERIES_ERROR_MSG = "First sort the <u>data</u> by query Id and Date then try again.";
export const AT_LEAST_ONE_SELECTION_REQUIRED = "at least one {Name} must be selected";


//Visit Messages
export const VISIT_REQUIRED_MSG = "Visit is required";
export const VISIT_NAME_ERROR_MSG = "Visit Name is required";
export const ANNOTATED_REQUIRED_MSG = "Annotated is required";
export const ANNOTATED_NAME_ERROR_MSG = "Annotated Name is required";
export const UNDERSCORE_NOT_ALLOWED = "_ is not allowed in annotation name";
export const VISIT_EXPORT_TO_PROD_CONFIRMATION_MSG = 'are you sure to export visit "{VISIT_NAME}" to production?';

//User Message
export const USER_NAME_ERROR_MSG = "Username is required";
export const SAME_USER_NAME_ERROR_MSG = "Username is same change the username";
export const FIRST_NAME_ERROR_MSG = "First Name is required";
export const LAST_NAME_ERROR_MSG = "Last Name is required";
export const STUDY_SELECTION_ERROR_MSG = "Select study to proceed";
export const ROLE_SELECTION_ERROR_MSG = "Select role to proceed";
export const SITE_SELECTION_ERROR_MSG = "Select atleast 1 site to proceed";
export const USER_STATUS_MSG = "Are you sure to change user status? ";
export const RESET_PASSWORD_MSG = "Are you sure you want to reset the Password?"
export const RESET_PASSWORD_SUCCESS_MSG = "Password Reset Successfully"
export const LOCK_MSG = "Are you sure you want to lock the status of the user?"
export const UNLOCK_MSG = "Are you sure you want to unlock the status of the user?"
export const USER_STATUS_REASON = "Enter reason";
export const DUPLICATE_STUDY_AND_ROLES_ASSIGNED_ERROR_MSG = "Combination of study, site and role already exists for User";

//Subject Message
export const SUBJECT_SELECTION_MSG = "Please select a subject!";
export const SUBJECT_STUDY_SELECTION_MSG = "Select subject who is in the study.";
export const SUBJECT_WITHDRAW_UPDATE_ERROR_MSG = "Unable to Update Subject, because subject is withdraw from study";
export const SUBJECT_ID_ERROR_MSG = "Subject Id is required";
export const SUBJECT_WITHDRAW_ERROR_MSG = "Subject is withdraw";
export const WITHDRAW_SUBJECT_MSG = "Are you sure you want to withdraw subject from this study?";
export const REJOIN_SUBJECT_MSG = "Are you sure you want to rejoin subject in this study?";
export const SUBJECT_WITHDRAW_CONFIRMATION_MSG = "Study completion form not found. Are you sure you want to withdraw this subject?";
export const SUBJECT_NOT_WITHDRAW_MSG = "Subject not withdraw";
export const SUBJECT_QUERY_COUNT_ERROR_MSG = "Queries are open for this subject. So can't withdraw.";

//Site Message
export const SITE_REQUIRED_MSG = "Site is required";
export const SITE_NAME_ERROR_MSG = "Name is Required";
export const SITE_CODE_ERROR_MSG = "Site code is Required";


//Study-Role Message
export const STUDY_ROLE_SELECTION_ERROR_MSG = "Select study and role to proceed!";

//Query-Raised Message
export const QUERY_CLOSED_MSG = "Query closed";
export const QUERY_REOPENED_MSG = "Query reopened";
export const QUERY_CLOSED_BY_DM_ERROR_MSG = "Query only close by Data Manager";

//Formula Message
export const FORMULA_INVALID_ERROR_MSG = "Formula is Invalid";
export const FORMULA_SAME_FIELD_TOGETHER_ERROR_MSG = "Two fields together";
export const FORMULA_NAME_ERROR_MSG = "Enter formula name";
export const FORMULA_FIELDS_ERROR_MSG = "Two fields together";
export const FORMULA_OPERATORS_ERROR_MSG = "Two operators together";
export const PARAMETER_ERROR_MSG = "Please select at least one parameter";
export const DATE_FORMULA_ERROR_MSG = "Please select a function";
export const CONSTANT_PARAMETER_ERROR_MSG = "Select constant date"

//Validation Message
export const MIN_MAX_VALIDATION_ERROR_MSG = "Min length should not be greater then max length.";
export const VALIDATION_SUBMISSION_ERROR_MSG = "Some data is missing or not fill properly. Are you sure you want to submit data?";
export const DEPENDENCY_VISIT_ERROR_MSG = "For Dependency visit is required";
export const DEPENDENCY_FORM_ERROR_MSG = "For Dependency form is required";
export const DEPENDENCY_FIELD_ERROR_MSG = "For Dependency field is required";

//Form Message
export const FORM_NAME_ERROR_MSG = "Form Name is required";
export const FORM_ANNOTATEDNAME_ERROR_MSG = "Form Annotated Name is required";
export const FORM_FORMTYPE = "Select formType/Visits";
export const COPY_FORM_ERR_MESSAGE = "Select a study before copying the form into visits. A study must be associated to proceed.";

//Field Message
export const FIELD_LABEL_ERROR_MSG = "Label is required";
export const FIELD_TAG_ERROR_MSG = "Tag is required";
export const FIELD_ANNOTATEDNAME_ERROR_MSG = "Annotated Name is required";
export const FIELD_HEADING_ERROR_MSG = "Heading is required";
export const MOVE_QUESTION = "Are you sure you want to move this question?"
export const FIELD_LINK_ERROR_MSG = "Link is required"

//Option Message
export const OPTION_NAME_ERROR_MSG = "Option name required";
export const OPTION_VALUE_ERROR_MSG = "Option value required";
export const OPTION_CODE_ERROR_MSG = "Option code required";
export const MINIMUM_OPTION_ERROR_MSG = "Minimum 1 option required";
export const LINK_ERROR_MSG = "Please enter valid link";

//Data Entry Message
export const MULTI_ROW_DELETE_MSG = "Are you sure you want to delete this row?";
export const REQUIRED_REASON_MSG = "Reason is required"
export const FORM_FILLUP_MSG = "Fillup the form first"

//Data Review Message
export const FORM_SUBMIT_FOR_REVIEW_SUCCESS_MSG = "Form submitted for review successfully";
export const FORM_REVIEW_NOT_REVIEWED_MSG = "Review not done for Forms: ";
export const REVIEW_FIELD_SUCCESS_MSG = "Review field successfully";
export const UNREVIEW_FIELD_SUCCESS_MSG = "Unreview field successfully";
// export const MONITOR_LOCK_SUCCESS_MSG = "Monitor lock status successfully";
// export const MONITOR_UNLOCK_SUCCESS_MSG = "Monitor unlock successfully";
export const SELECT_FIELD_FOR_SUMBIT_FOR_REVIEW = "Select Field(s) to perform Submit for Review";
export const SUBMIT_FOR_REVIEW_SUCCESS_MSG = "Submit For Review Mark Done";
export const CRA_REVIEW_SUCCESS_MSG = "SDV Mark Done";
export const CRA_UNREVIEW_SUCCESS_MSG = "NON SDV Mark Done";
export const DM_REVIEW_SUCCESS_MSG = "DM Review Mark Done";
export const DM_UNREVIEW_SUCCESS_MSG = "DM Unreview Mark Done";
export const INV_REVIEW_SUCCESS_MSG = "Investigator Signature Done";
export const INV_UNREVIEW_SUCCESS_MSG = "Investigator Signature Mark Undone";
export const SIGNATURE_FILE_SIZE_MSG = "The maximum file size that can be uploaded is 2MB.";


export const UNFREEZE_SUCCESS_MSG = "Unfreeze field successfully";
export const SITE_SELECT_MSG = "Select site";
export const SUBJECT_SELECT_MSG = "Select subject";
export const VISIT_SELECT_MSG = "Select visit";
export const FORM_SELECT_MSG = "Select form";
export const STATUS_SELECT_MSG = "Select status";


//Query Close Message
export const QUERY_CLOSE_SUCCESS_MSG = "Query closed";
export const QUERY_REPLY_SUCCESS_MSG = "Enter message";
export const QUERY_MESSAGE_SUCCESS_MSG = "Enter query message";
export const QUERY_SELECT_STATUS_SUCCESS_MSG = "Select status";
export const QUERY_SELECT_ASSIGNED_TO_SUCCESS_MSG = "Select assigned to";
export const QUERY_SELECT_QUERY_PRIORITY_SUCCESS_MSG = "Select query priority";
export const QUERY_CLOSE_ERROR_MSG = "Select the query to close";
export const ASSIGNED_TO_REQUIRED_MSG = "Assigned To is required";
export const REASON_REQUIRED_MSG = "Reason is required";

//Multi-Level Component Message
export const COLUMN_REQUIRED_MSG = "Minimum 1 Column Required";

//Change Password Message
export const CURRENT_PASSWORD_NULL_MSG = "Enter old password";
export const NEW_PASSWORD_NULL_MSG = "Enter new password";
export const CONFIRM_PASSWORD_NULL_MSG = "Enter confirm new password";
export const PASSWORD_NOT_MATCH_MSG = "New password & confirm password didn't match.";
export const USERID_NULL_MSG = "User Id is null.";
export const PASSWORD_SPECIAL_CHAR_ERROR_MSG = "Password should include combination of upper and lowe case, number and special characters"
export const PASSWORD_REQUIRED_MSG = "Password is required";
export const PASSWORD_LENGTH_ERROR_MSG = "Password must be at least 8 characters"
//Dashboard Messages
export const SITEWISE_RECRUITMENT_STATUS = "Sitewise recruitment status";
export const QUERY_STATUS_COUNT = "Query Status";
export const VISIT_SUBJECT_COUNT = "Visitwise subject count";

// Session Logout Messaage
export const SESSION_LOGOUT = "Logging out due to inactivity";

// drag-drop message
export const DRAG_DROP_MESSAGE = "Are you sure you want to change order of an option?"

//Time field Validation
export const TIME_ERROR_MESSAGE = "Select a time format to display";
export const TIME_SELECTION_MESSAGE = "Select any one time data";

//Formula Messages
export const FORMULA_DELETE_SUCCESS_MESSAGE = "Formula deleted successfully";

//Subject Field Level Comments
export const ENTER_COMMENT_MESSAGE = "Enter Valid Message";
export const DATA_NOT_AVAILABLE_MESSAGE = "Are you sure data is not available?";
export const DATA_AVAILABLE_MESSAGE = "Are you sure data is available?";

//Indication Message
export const INDICATION_NAME = "Enter Indication";
export const SELECT_THERAPEUTIC = "Select Therapeutic";

//soft Lock Message
export const VISIT_SOFT_LOCK_MSG = "Visit soft lock pending for: ";
export const SUBJECT_SOFT_LOCK_MSG = "Subject soft lock pending for: ";

export const VISIT_CONFIRM_SOFT_LOCK_MSG = "Are you sure you want to lock selected visit(s)?";
export const VISIT_CONFIRM_SOFT_UNLOCK_MSG = "Are you sure you want to unlock selected visit(s)?";
export const SUBJECT_CONFIRM_SOFT_LOCK_MSG = "Are you sure you want to lock selected subject(s)?";
export const SUBJECT_CONFIRM_SOFT_UNLOCK_MSG = "Are you sure you want to unlock selected subject(s)?";

export const OPEN_RESPONDED_QUERY_MSG = "To continue locking, close the queries.";

export const FORM_CONFIRM_SOFT_LOCK_MSG = "Are you sure you want to lock selected log form(s)?";
export const FORM_CONFIRM_SOFT_UNLOCK_MSG = "Are you sure you want to unlock selected log form(s)?";
export const FORM_EXPORT_CONFIRMATION = "Are you sure you want to Export this Form to prod?"

export const SDV_LIST_EMPTY = "Select fields for SDV/Non SDV";
export const DM_LIST_EMPTY = "Select fields for DM Review/Unreview";
export const INV_LIST_EMPTY = "Select fields for Investigator PI Sign/PI Unsign";

export const SUBMIT_FOR_REVIEW_DATAPOINT = "Applicable data point(s) Submit for review already done";
export const NON_SDV_DATAPOINT = "Applicable data point(s) non SDV already done";
export const SDV_DATAPOINT = "Applicable data point(s) SDV already done";
export const UNREVIEW_DM_DATAPOINT = "Applicable data point(s) Unreview DM already done";
export const REVIEW_DM_DATAPOINT = "Applicable data point(s) review DM already done";
export const UNSIGNED_INV_DATAPOINT = "Applicable data point(s) PI Unsign already done";
export const SIGNED_INV_DATAPOINT = "Applicable data point(s) PI Sign already done";

export const SUBMIT_FOR_REVIEW_FORM_LEVEL = "Applicable form(s) Submit for review already done";
export const NON_SDV_FORM_LEVEL = "Applicable form(s) non SDV already done";
export const SDV_FORM_LEVEL = "Applicable form(s) SDV already done";
export const UNREVIEW_DM_FORM_LEVEL = "Applicable form(s) Unreview DM already done";
export const REVIEW_DM_FORM_LEVEL = "Applicable form(s) review DM already done";
export const UNSIGNED_INV_FORM_LEVEL = "Applicable form(s) PI Unsign already done";
export const SIGNED_INV_FORM_LEVEL = "Applicable form(s) PI Sign already done";

export const VISIT_LOCK_ALREADY_DONE = "Applicable visit(s) lock already done";
export const VISIT_UNLOCK_ALREADY_DONE = "Applicable visit(s) unlock already done";

export const LOG_FORM_LOCK_ALREADY_DONE = "Applicable log form(s) lock already done";
export const LOG_FORM_UNLOCK_ALREADY_DONE = "Applicable log form(s) unlock already done";


export const FIELD_REORDERING_MSG = "Reordering done successfully";


export const IMPACT_IS_NULL = "Validation : Impact value is required";
export const USER_MANUAL_ERROR_MSG = "error while getting User Manual";
export const HELP_DOCUMENT_DOWNLOAD_STARTS = "Help Document download initiated";
export const DOWNLOAD_REPORT_STARTED = "Report download process initiated";

