
export type LogFormRedirect = {
    dataTypeId:string|number,
    fieldId:number,
    fieldLabel:string,
    fieldValue:string,
    formId:number,
    multiRowFieldLabel:string,
    rowId:string,
    subjectId:string,
    visitId:string,
    status:boolean,
}

export class LogFormRedirectDTO implements LogFormRedirect {
    dataTypeId: string|number;
    fieldId: number;
    fieldLabel: string;
    fieldValue: string;
    formId: number;
    multiRowFieldLabel: string;
    rowId: string;
    subjectId: string;
    visitId: string;
    status: boolean;

    constructor(){
        this.dataTypeId = "";
        this.fieldId = -1;
        this.fieldLabel = '';
        this.fieldValue = '';
        this.formId = -1;
        this.multiRowFieldLabel = '';
        this.rowId = '';
        this.subjectId = '';
        this.visitId = '';
        this.status = false;
    }
}
export type TableHeader = {
    multiRowId:string;
    fieldId:string;
    orderId:number;
    label:string;
}

export class TableHeaderDTO implements FilterJson,TableHeader {
    multiRowId: string = '';
    fieldId: string = '';
    formId: string = '';
    orderId: number = -1;
    label: string = '';

    constructor( multiRowId:string, fieldId:string, orderId:number, label:string ){
        this.multiRowId = multiRowId;
        this.fieldId = fieldId;
        this.label=label;
        this.orderId=orderId;
    }
}

export type FilterJson = {
    fieldId:string;
    multiRowId:string;
    orderId:number;
}

export class FilterJsonDTO implements FilterJson {
    multiRowId: string = '';
    fieldId: string = '';
    orderId: number = -1;

    constructor( multiRowId:string, fieldId:string, orderId:number ){
        this.multiRowId = multiRowId;
        this.fieldId = fieldId;
        this.orderId=orderId;
    }
}
