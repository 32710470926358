import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { RolesStudyService } from 'src/app/services/roles-study.service';
import { ToastrService } from 'ngx-toastr';
import { ROLE_SELECTION_ERROR_MSG, STUDY_SELECTION_ERROR_MSG } from 'src/app/constant/responseMessage';
import { StudyService } from 'src/app/services/study.service';
import { AuditlogService } from 'src/app/services/auditlog.service';
@Component({
  selector: 'app-roles-and-study',
  templateUrl: './roles-and-study.component.html',
  styleUrls: ['./roles-and-study.component.css']
})
export class RolesAndStudyComponent implements OnInit {
  allData?: any[];
  formValue?: FormGroup;
  roleData:any[] = []
  loading:boolean = false
  studyId:any
  userName:any
  constructor(private rolesStudy: RolesStudyService, private route: Router,private toastr:ToastrService,
    private studyAPI:StudyService,private auditLogService:AuditlogService) {
    this.formValue = new FormGroup({
      study: new FormControl(),
      role: new FormControl()
    })
  }
  ngOnInit(): void {
    this.getStorageData()
    this.getData();
  }
  getStorageData(){
    this.userName = sessionStorage.getItem("userName")
  }
  getData(): void {
    this.rolesStudy.getRolesStudy().subscribe(data => {
      this.allData = data;
      if(this.studyId){
        this.formValue?.get("study")?.setValue(this.studyId)
        this.roleData = this.allData?.find((cntry: any) => cntry.studyId == this.studyId).roleIds;
        // this.roleData = this.roleData.filter((ctry:any) => ctry.id != 9)
      }
    });
  }
  onItemSelect(event:any){
    this.studyId = event.value
    this.roleData = this.allData?.find((cntry: any) => cntry.studyId == event.value).roleIds;
    // this.roleData = this.roleData.filter((ctry:any) => ctry.id != 9)
  }
  validateLoginCredentials(){
    if(this.formValue?.value.study == null || this.formValue?.value.study == ''){
      this.toastr.warning(STUDY_SELECTION_ERROR_MSG)
      return false
    }else if(this.formValue?.value.role == null || this.formValue?.value.role == ''){
      this.toastr.warning(ROLE_SELECTION_ERROR_MSG)
      return false
    }
    return true
  }
  login() {//Submit selected Role and Study
    this.loading = true
    if (this.validateLoginCredentials()) {
      this.allData?.forEach(data => {
        if (data.studyId == this.formValue?.value.study) {
          sessionStorage.setItem("studyId", data.studyId);
          sessionStorage.setItem("studyName", data.name);
          sessionStorage.setItem("isLocked", data.isLocked);
          return;
        }
      });
      sessionStorage.setItem("role", this.formValue?.value.role);
      this.route.navigate(['/layout/dashboard']);
      this.loading = false
      this.saveLoggedInLog();
      this.submitData()
    } else {
      this.loading = false
    }
  }
  saveLoggedInLog() {
    this.auditLogService.postLoggedInLog().subscribe((res:any) => {},err => {
      this.toastr.error("Auditlog save failed for login")
    });
  }
  cancelLogin(){
    this.route.navigate([''])
    localStorage.clear()
    sessionStorage.clear()
  }

  submitData(){
    //localStorage.setItem("tempStudyId",this.studyId)
    if( this.userName != 'admin' ){
      this.loading = true
      this.studyAPI.getStudyDateFormat().subscribe((res:any) => {
        if(res.status == 0 && res.responseObject != undefined){
          let dateFormat = String(res.responseObject).toLowerCase()
          if(dateFormat.includes("mmm")){
            dateFormat = dateFormat.replace("mmm","MMM")
          }else if(dateFormat.includes("mm")){
            dateFormat = dateFormat.replace("mm","MM")
          }
          sessionStorage.setItem("studyDateFormat",dateFormat + " hh:mm:ss a")
        }else{
          sessionStorage.setItem("studyDateFormat","dd/MM/y hh:mm:ss a")
        }
        this.loading = false
      },error => {
        this.loading = false
      })
    }
  }
}
