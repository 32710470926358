import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RandomizationService } from '../randomization.service';
import { FormControl, FormGroup } from '@angular/forms';
import { EventHandlerService } from 'src/app/services/event-handler.service';

@Component({
  selector: 'randomization-field-container',
  templateUrl: './randomization-field-container.component.html',
  styleUrls: ['./randomization-field-container.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class RandomizationFieldContainerComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() randomizationFieldsDetails!: Array<any>;
  @Input() isEdit: boolean = false;
  @Input() subjectId: any;
  @Input() visitId: any;
  @Input() formId: any;
  @Input() roleId!: number;

  @Output() onRandomizedSubject: EventEmitter<any> = new EventEmitter<any>();

  randomizationForm!: FormGroup;
  randomizationFields!: Array<any>;
  randomizationNumberValue: string = '';
  eventSubscription: any;

  constructor(private randomizationService: RandomizationService,
              private changeDetectorRef: ChangeDetectorRef,
              private eventHandlerService: EventHandlerService) {  
                this.eventSubscription = this.eventHandlerService?.onClickOnSubjectId?.subscribe((data => {
                  this.visitId = data?.visitId;
                  this.formId = data?.formId;
                  this.subjectId = data?.subjectId;
                }));
  }

  ngOnInit(): void {
    this.roleId = Number(this.roleId);
    this.randomizationForm = new FormGroup({
      number: new FormControl({disabled: true,value: ""}),
      arm: new FormControl({disabled: true,value: ""}),
      date: new FormControl({disabled: true,value: ""})
    });
    this.onRandomizedSubject.emit(this.isRandomizedButtonDisabled())
    this.changeDetectorRef?.detectChanges();
  }

  ngAfterViewInit(): void {
  }

  getRandomizationData = (): void => {
    const options = this.getQueryParams();
    this.randomizationService?.getRandomizationFormData(options)?.subscribe({
      next: (randomizationData: any) => {
        this.setRandomizationFormValues(randomizationData?.responseObject?.formData);
      },
      error: () => {
        this.resetRandomizationForm();
      }
    });
  }

  isRandomizedButtonDisabled = (): boolean => {
    return ([1, 3, 4, 5, 6, 7, 8].includes(this.roleId) || this.randomizationForm.get('number')?.value)
  }

  resetRandomizationForm = (): void => {
    this.randomizationForm?.get('number')?.setValue("");
    this.randomizationForm?.get('arm')?.setValue("");
    this.randomizationForm?.get('date')?.setValue("");
  }

  getQueryParams = (): any => {
    return {
      subjectId: this.subjectId,
      visitId: this.visitId,
      formId: this.formId
    };
  }

  setRandomizationFormValues = (randomizationFormFieldsDetail: any): void => {    
    this.randomizationFieldsDetails = this.randomizationFieldsDetails?.map(field => {
      return {
        formControlName: this.getFormControlNameAndSetRandomizationFormValue(field?.orderId, randomizationFormFieldsDetail[field?.id]),
        ...field
      }
    })
    this.changeDetectorRef?.detectChanges();
  }

  updateRandomizationFields = (data: any): void => {
    this.randomizationFields = data;
    this.changeDetectorRef?.detectChanges();
  }

  getFormControlNameAndSetRandomizationFormValue = (fieldOrderId: any, value: any): string => {
    let formControlName = '';
    if (fieldOrderId == 1) {
      this.randomizationForm?.get('number')?.setValue(value);
      formControlName = 'number';
    } else if (fieldOrderId == 2) {
      this.randomizationForm?.get('arm')?.setValue(value);
      formControlName = 'arm';
    } else if (fieldOrderId == 3) {
      const date = value?.day + '-' + value?.month + "-" + value?.year;
      this.randomizationForm?.get('date')?.setValue(date);
      formControlName = 'date'
    }
    this.onRandomizedSubject.emit(this.isRandomizedButtonDisabled())
    return formControlName;
  }

  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription?.unsubscribe();
    }
  }
}
