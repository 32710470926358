import { baseURL, VERSION } from 'src/app/constant/constant';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getTimeZoneHeader } from '../constant/globalFunction';

@Injectable({
  providedIn: 'root'
})
export class FieldQueryService {
  constructor(private http:HttpClient) { }

  getFieldQueryData(formId:any,fieldId:any,visitId:any,subjectId:any,rowId:any,multiRowId:any):Observable<any>{
    return this.http.get(`${baseURL}fields/query/${VERSION}/details?formId=${formId}&fieldId=${fieldId}&visitId=${visitId}&subjectId=${subjectId}&rowId=${rowId}&multiRowId=${multiRowId}`)
  }
  
  postFieldQueryDetail(data:any):Observable<any>{
    return this.http.post(`${baseURL}fields/query/${VERSION}/response`,data)
  }

  postFieldQuery(data:any):Observable<any>{
    return this.http.post(`${baseURL}fields/query/${VERSION}`,data)
  }

  queryStatus(data:any):Observable<any>{
    return this.http.put(`${baseURL}fields/query/${VERSION}/status`,data)
  }

  findByQueryId(queryId:any):Observable<any>{
    return this.http.get(`${baseURL}fields/query/${VERSION}/get?queryId=${queryId}`);
  }

  queryList(sites:any,subjects:any,visits:any,forms:any,status:any,type:any,qeuryIds:number[],pageNo:any,pageSize:any):Observable<any>{
    return this.http.get(`${baseURL}fields/query/${VERSION}/list?sites=${sites}&subjects=${subjects}&visits=${visits}&forms=${forms}&status=${status}&types=${type}&queryIds=${qeuryIds}&pageNo=${pageNo}&pageSize=${pageSize}`);
  }
  
  export(sites:any,subjects:any,visits:any,forms:any,ids:number[],status:any,types:any[]):Observable<any>{
    return this.http.get(`${baseURL}fields/query/${VERSION}/export?sites=${sites}&subjects=${subjects}&visits=${visits}&forms=${forms}&status=${status}&ids=${ids}&types=${types}`,{headers:getTimeZoneHeader()});
  }
  
  getQueryIdList():Observable<any>{
    return this.http.get(`${baseURL}fields/query/${VERSION}/queryIds`);
  }
  
  getStatusQueryCount(sites: any, subjects: any, visits: any, forms: any, status: any, ids: number[], types: any):Observable<any>{
    return this.http.get(`${baseURL}fields/query/v1/statusCount?sites=${sites}&subjects=${subjects}&visits=${visits}&forms=${forms}&status=${status}&ids=${ids}&types=${types}`,{headers:getTimeZoneHeader()})  
  }

  getQueryAgeCount(sites:any,subjects:any,visits:any,forms:any,queryAge?:any):Observable<any>{
    return this.http.get(`${baseURL}report/v1/queryAgeDaysCount?siteIds=${sites}&subjectIds=${subjects}&visitIds=${visits}&formIds=${forms}&queryAge=${queryAge}`,{headers:getTimeZoneHeader()})  
    // return this.http.get(`${baseURL}report/v1/queryAgeDaysCount`,{headers:getTimeZoneHeader()})  
  }

  // reopenQuery(data:any):Observable<any>{
  //   return this.http.put(`${baseURL}review/${VERSION}/reOpen`,data)
  // }
  
  // freezeLockField(data:any):Observable<any>{
  //   return this.http.post(`${baseURL}review/${VERSION}/fieldStatus`,data)
  // }
}
