import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Components, tempFormData } from 'src/app/constant/constant';
import { Role } from 'src/app/models/Role';
import { FieldService } from 'src/app/services/field.service';

@Component({
  selector: 'app-randomization-field-container-dialog',
  templateUrl: './randomization-field-container-dialog.component.html',
  styleUrls: ['./randomization-field-container-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class RandomizationFieldContainerDialogComponent implements OnInit {

  @Input() showComponentName: string = '';
  @Input() editFormId: any;
  @Input() editFieldId: any;
  @Input() roleId: any;
  @Input() randomizationFields: any;

  readOnlyRoleId: number = 0; 
  randomizationForm: FormGroup;
  validationMessageForLabel: string = '';
  validationMessageForArm: string = '';
  validationMessageForDate: string = '';

  constructor(private ngbActiveModal: NgbActiveModal,
              private fieldService: FieldService,
              private toasterService: ToastrService,
              private changeDetectorRef: ChangeDetectorRef) {
    this.randomizationForm = new FormGroup({});  
  }

  ngOnInit(): void {
    this.initializeVariables();
    this.changeDetectorRef?.detectChanges();
  }

  initializeVariables = (): void => {
    this.readOnlyRoleId = Role.ReadOnly;
    this.randomizationForm.addControl('randomizationNumberLabel', new FormControl());
    this.randomizationForm.addControl('randomizationArmLabel', new FormControl());
    this.randomizationForm.addControl('randomizationDateLabel', new FormControl());
    this.randomizationForm.get('randomizationNumberLabel')?.setValue(this.randomizationFields[0]?.label);
    this.randomizationForm.get('randomizationArmLabel')?.setValue(this.randomizationFields[1]?.label);
    this.randomizationForm.get('randomizationDateLabel')?.setValue(this.randomizationFields[2]?.label);
  }

  setValidationMessages = (randomizationFields: any): void => {
    randomizationFields?.map((field: any) => {
      if (field?.orderId == 1) {
        this.validationMessageForLabel = "Label for Number is required"
      } else if (field?.orderId == 2) {
        this.validationMessageForArm = "Label for Arm is required"
      } else if (field?.orderId == 3) {
        this.validationMessageForDate = "Label for Date is required"
      }
    })
    this.changeDetectorRef?.detectChanges();    
  }

  onSaveRandomizationFields = (): void => {
    const randomizationFields = this.getPostData();
    this.setValidationMessages(randomizationFields);
    if (this.isValidRandomizationForm()) {
      const data = {
        isRandomizationFieldAvailable: true,
        randomizationFields: randomizationFields
      };
  
      if (this.editFormId && this.editFormId != 'null') {
        randomizationFields?.forEach((field: any, index: number) => {
          const api = this.editFieldId ? this.fieldService?.updateFormField(field) : this.fieldService?.addFormField(field);
          api?.subscribe({
            next: (res) => {
              randomizationFields[index]['id'] = res?.responseObject?.id
              localStorage.setItem("randomizationFields",JSON.stringify(this.randomizationFields))
              this.closeModal(data);
            },
            error: () => {
              this.closeModal();
            }
          });
        })
      } else {
        this.closeModal(data);
      }
    }
    this.changeDetectorRef?.detectChanges();
  }

  isValidRandomizationForm = (): boolean => {    
    if (!this.randomizationForm?.get('randomizationNumberLabel')?.value?.trim()) {
      this.toasterService?.error(this.validationMessageForLabel);
      return false;
    } else if (!this.randomizationForm?.get('randomizationArmLabel')?.value?.trim()) {
      this.toasterService?.error(this.validationMessageForArm);
      return false;
    }
    else if (!this.randomizationForm?.get('randomizationDateLabel')?.value?.trim()) {
      this.toasterService?.error(this.validationMessageForDate);
      return false;
    }
    return true;
  }

  getPostData = (): any => {
    return Object.keys(this.randomizationForm?.value || {})?.map((field: any, index: number) => {
      return {
        id: this.randomizationFields[index]?.id || null,
        formId: this.editFormId || null,
        type: this.showComponentName,
        dataTypeId: Components.indexOf(this.showComponentName) + 1,
        orderId: index + 1,
        label: this.randomizationForm?.value[field]
      }
    });
  }

  closeModal = (data?: any): void => {
    this.ngbActiveModal?.close(data);
  }
}
